import { useRef, useEffect, useState } from "react";
import {
  Text,
  Input,
  Button,
  Box,
  Flex,
  Avatar,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Skeleton,
} from "@chakra-ui/react";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { IoReload } from "react-icons/io5";
import axios from "axios";
import Cookies from "js-cookie";
import { Card, CardContent, CardFooter } from "./ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";

function CommissionsPage() {
  const inputRef = useRef();
  const formRef = useRef();
  const contentRef = useRef();
  const toast = useToast();

  const [isAdmin, setIsAdmin] = useState(false);
  const [staffMembers, setStaffMembers] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("0");
  const [commissions, setCommissions] = useState([]);
  const [totalCommissions, setTotalCommissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [activeTab, setActiveTab] = useState("current");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (inputRef.current.value.trim() === "1234") {
      if (!fromDate || !toDate) {
        toast({
          title: "Fejl",
          description: "Vær venlig at angive både start- og slutdato.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      formRef.current.style.display = "none";
      contentRef.current.style.display = "block";
      setIsAdmin(true);
    } else {
      toast({
        title: "Ugyldig adgangskode",
        description: "Venligst indtast den korrekte adgangskode",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const formatDateForTable = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // Set formatted dates
    setFromDate(formatDateForInput(startOfMonth));
    setToDate(formatDateForInput(endOfMonth));
  }, []);

  useEffect(() => {
    const fetchStaffMembers = async () => {
      try {
        const res = await axios.get("staff", {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        });
        if (res.status === 200) {
          setStaffMembers(res.data);
        }
      } catch (err) {
        toast({
          title: "Fejl ved hentning af medarbejdere",
          description: "Kunne ikke hente medarbejdere.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchStaffMembers();
  }, []);

  const fetchCommissions = async () => {
    if (!fromDate || !toDate) {
      toast({
        title: "Fejl",
        description: "Vær venlig at angive både start- og slutdato.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const staffId = selectedStaff === "" ? 0 : selectedStaff;

    setLoading(true); // Set loading to true before fetching

    if (activeTab === "current") {
      try {
        const res = await axios.get(`/commissions/${staffId}/records`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
          params: {
            page: currentPage,
            start_date: fromDate,
            end_date: toDate,
          },
        });
        if (res.status === 200) {
          setCommissions(res.data.commissions.commissionsList);
          setTotalPages(res.data.commissions.totalPages);
        }
      } catch (err) {
        toast({
          title: "Fejl ved hentning af kommissioner",
          description: "Kunne ikke hente kommissioner.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    } else if (activeTab === "total") {
      fetchTotalCommissions(staffId);
    }
  };

  const fetchTotalCommissions = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/commissions/totals`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("authenticationToken"),
        },
        params: {
          start_date: fromDate,
          end_date: toDate,
        },
      });
      if (res.status === 200) {
        setTotalCommissions(
          res.data.commissions.map((commission) => ({
            staffId: commission.staffId,
            staffName: commission.staffName,
            staffImage: commission.staffImage,
            totalAmount: commission.totalAmount,
            totalCommissions: commission.totalCommissions,
          }))
        );
      }
    } catch (err) {
      toast({
        title: "Fejl ved hentning af total kommissioner",
        description: "Kunne ikke hente total kommissioner.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      fetchCommissions();
    }
  }, [selectedStaff, currentPage, fromDate, toDate, activeTab]);

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    fetchCommissions();
  };

  const formatMoney = (amount) => {
    return (amount / 100).toFixed(2).replace(".", ",") + " kr";
  };

  return (
    <Box
      className="CommissionsPage flex min-h-screen w-full flex-col bg-muted/40"
      style={{
        height: "100vh",
        backgroundColor: "#edeadf",
        overflowY: "scroll",
      }}
    >
      <Flex
        ref={formRef}
        alignItems={"center"}
        justifyContent={"center"}
        mt="4"
      >
        <Box className="Emptylistcontainer">
          <Text marginBottom="10px">Indtast administratoradgangskode</Text>
          <Text marginBottom={"24px"} fontWeight={"bold"}>
            (password 1234)
          </Text>
          <Input
            marginBottom="24px"
            type="password"
            placeholder="Adgangskode"
            ref={inputRef}
          />
          <Button color="white" bgColor="black" onClick={handleSubmit}>
            Indsend
          </Button>
        </Box>
      </Flex>
      <Box ref={contentRef} display={"none"} mt="20px" mx={"20px"}>
        <Flex flexDirection="column" mb={4} gap={2} w={"full"}>
          <Text fontWeight="bold" fontSize="xl" mb="4">
            Provisioner
          </Text>
          <Flex
            mb={4}
            gap={2}
            w={"full"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs defaultValue="current" variant="enclosed">
              <TabsList>
                <TabsTrigger
                  value="current"
                  onClick={() => handleTabChange("current")}
                  selected={activeTab === "current"}
                >
                  Nuværende
                </TabsTrigger>
                <TabsTrigger
                  value="total"
                  onClick={() => handleTabChange("total")}
                  selected={activeTab === "total"}
                >
                  Totaler
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor={"white"}
                width="300px"
                rightIcon={<ArrowDownIcon />}
                isDisabled={activeTab === "total"}
              >
                {selectedStaff === "0" ? (
                  "Alle medarbejdere"
                ) : (
                  <Flex alignItems="center" gap={8}>
                    <Avatar
                      src={
                        staffMembers.find((staff) => staff.id === selectedStaff)
                          ?.image
                      }
                      size="sm"
                      mr={2}
                    />
                    {
                      staffMembers.find((staff) => staff.id === selectedStaff)
                        ?.name
                    }
                  </Flex>
                )}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setSelectedStaff("0")}>
                  <Flex alignItems="center">Alle medarbejdere</Flex>
                </MenuItem>
                {staffMembers.map((staff) => (
                  <MenuItem
                    key={staff.id}
                    onClick={() => setSelectedStaff(staff.id)}
                  >
                    <Flex alignItems="center">
                      <Avatar src={staff.image} size="sm" mr={2} />
                      {staff.name}
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Flex gap={2} alignItems="center">
              <Input
                type="date"
                placeholder="Fra"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                size="md"
                bgColor="white"
              />
              <Input
                type="date"
                placeholder="Til"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                size="md"
                bgColor="white"
              />
              <IconButton
                icon={<IoReload />}
                onClick={fetchCommissions}
                aria-label="Opdater"
                size="md"
                borderRadius="md"
              />
            </Flex>
          </Flex>
        </Flex>
        <Card>
          <CardContent>
            {loading ? (
              <Skeleton height="20px" my={2} />
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Medarbejder</TableHead>
                    <TableHead>Beløb</TableHead>
                    <TableHead>Kommission</TableHead>
                    {activeTab === "current" && <TableHead>Dato</TableHead>}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {activeTab === "current"
                    ? commissions.map((commission, key) => (
                        <TableRow key={key}>
                          <TableCell>
                            <Flex alignItems="center">
                              <Avatar
                                src={commission.staffImage}
                                size="sm"
                                mr={2}
                              />
                              {commission.staffName}
                            </Flex>
                          </TableCell>
                          <TableCell>
                            {formatMoney(commission.ammount)}
                          </TableCell>
                          <TableCell>
                            {formatMoney(commission.commission)}
                          </TableCell>
                          <TableCell>
                            {formatDateForTable(new Date(commission.date))}
                          </TableCell>
                        </TableRow>
                      ))
                    : totalCommissions.map((total, key) => (
                        <TableRow key={key}>
                          <TableCell>
                            <Flex alignItems="center">
                              <Avatar src={total.staffImage} size="sm" mr={2} />
                              {total.staffName}
                            </Flex>
                          </TableCell>
                          <TableCell>
                            {formatMoney(total.totalAmount)}
                          </TableCell>
                          <TableCell>
                            {formatMoney(total.totalCommissions)}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
          <CardFooter>
            <Flex justifyContent="space-between" w="full" direction={"column"}>
              <Text className="text-xs text-muted-foreground">
                Viser{" "}
                {activeTab === "current"
                  ? commissions.length
                  : totalCommissions.length}{" "}
                kommissioner
              </Text>
              {activeTab === "current" && (
                <Flex alignItems="center" justifyContent="center" gap={2}>
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    isDisabled={currentPage === 1}
                    onClick={() => handlePageChange("prev")}
                    aria-label="Previous Page"
                    size="md"
                    borderRadius="md"
                  />

                  {/* Pagination Buttons */}
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        style={{
                          border: "2px #e2e8f0 solid",
                          padding: "5px",
                          width: "35px",
                          margin: "0 2px",
                          backgroundColor:
                            currentPage === pageNumber ? "#e2e8f0" : "white",
                        }}
                      >
                        {pageNumber}
                      </button>
                    );
                  })}

                  <IconButton
                    icon={<ChevronRightIcon />}
                    isDisabled={currentPage === totalPages}
                    onClick={() => handlePageChange("next")}
                    aria-label="Next Page"
                    size="md"
                    borderRadius="md"
                  />
                </Flex>
              )}
            </Flex>
          </CardFooter>
        </Card>
      </Box>
    </Box>
  );
}

export default CommissionsPage;
