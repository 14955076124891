import React, { useState } from "react";
import "./Loginleft.css";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import axios from "axios";
import Cookies from "js-cookie";

function Loginleft() {
  const [isLoading, setisLoading] = useState(false);
  const toast = useToast();
  const [show, setShow] = React.useState(false);
  const [inputs, setInputs] = useState({ email: "", password: "" });

  const handleClick = () => setShow(!show);

  function setCookiePromise(key, value, options) {
    return new Promise((resolve, reject) => {
      try {
        Cookies.set(key, value, options);
        resolve("Cookie sat korrekt");
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);

    if (!inputs.email || !inputs.password) {
      toast({
        title: "Inputfejl",
        description: "E-mail- eller adgangskodefeltet er tomt.",
        position: "top-right",
        status: "warning",
        isClosable: true,
      });
      setisLoading(false);
      return;
    }

    try {
      const res = await axios.post("/saloon/login", inputs, {
        headers: { "Content-Type": "application/json" },
      });

      localStorage.setItem("firestoreIdentifier", res.data.firestoreIdentifier);

      if (res.status === 200) {
        await setCookiePromise("authenticationToken", res.data.token, {
          expires: 365,
        });
        window.location.href = "/bookingsystem/kalender";
      }
    } catch (err) {
      const errorStatus = err.response?.status;
      if (errorStatus === 400) {
        toast({
          title: "Loginfejl",
          description: "Ugyldig e-mail eller adgangskode. Prøv igen.",
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      } else if (errorStatus === 404) {
        toast({
          title: "Loginfejl",
          description: "E-mail ikke registreret.",
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      } else if (errorStatus === 401) {
        toast({
          title: "Loginfejl",
          description: "Forkert adgangskode.",
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      } else if (errorStatus === 500) {
        toast({
          title: "Serverfejl",
          description: "Prøv venligst igen senere.",
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: "Ukendt fejl",
          description: "En uventet fejl opstod.",
          position: "top-right",
          status: "error",
          isClosable: true,
        });
      }
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div className="Loginleft">
      <Link to="/">
        <img
          src="../assets/Logo/park_logo.png"
          alt=""
          className="Logocontainer"
        />
      </Link>
      <div className="Header">Velkommen tilbage!</div>
      <div className="Subheader">
        Indtast venligst dine oplysninger for at logge ind.
      </div>
      <form>
        <div className="Label">E-mail</div>
        <Input
          name="email"
          required
          variant="filled"
          placeholder="Indtast din e-mail"
          size="lg"
          className="Emailinput"
          type="email"
          _hover={{ background: "#edeadf", border: "black solid 1px" }}
          _focus={{ background: "#edeadf", border: "black solid 1px" }}
          onChange={handleChange}
        />
        <div className="Passlabel">Adgangskode</div>
        <InputGroup className="Passinput">
          <Input
            name="password"
            required
            type={show ? "text" : "password"}
            placeholder="Indtast din adgangskode"
            variant="filled"
            className="Password"
            _hover={{ background: "#edeadf", border: "black solid 1px" }}
            _focus={{ background: "#edeadf", border: "black solid 1px" }}
            onChange={handleChange}
          />
          <InputRightElement width="4.5rem">
            <Button
              onClick={handleClick}
              className="ShowHide"
              background="none"
              _hover={{ background: "none" }}
              _focus={{ background: "none" }}
            >
              {show ? <FaRegEyeSlash /> : <FaRegEye />}
            </Button>
          </InputRightElement>
        </InputGroup>
        <div className="Fergotpass">
          <Link to="/booking-system/fergotpassword">Glemt adgangskode?</Link>
        </div>
        <Button
          className="Submitbtn"
          onClick={handleSubmit}
          isLoading={isLoading}
          loadingText="Indlæser"
        >
          Log ind
        </Button>
      </form>
    </div>
  );
}

export default Loginleft;
