import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Businesssidebar.css";
import { Icon } from "@chakra-ui/icons";
import {
  MdSpaceDashboard,
  MdCalendarToday,
  MdEditCalendar,
  MdMedicalServices,
  MdPersonAddAlt1,
  MdOutlineStarHalf,
  MdCreditCard,
  MdUpgrade,
  MdSettings,
  MdOutlineLogout,
  MdQueryStats,
} from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { Text, useToast } from "@chakra-ui/react";
import { BsBoxSeam } from "react-icons/bs";
import Cookies from "js-cookie";
import axios from "axios";

function BusinessSideBar() {
  const toast = useToast();
  const [salonName, setSalonName] = useState("")
  // handle removing cookies
  function removeCookiePromise(key) {
    return new Promise((resolve, reject) => {
      try {
        Cookies.remove(key);
        resolve("Cookie removed successfully");
      } catch (error) {
        reject(error);
      }
    });
  }

  // handle logging out of dashboard
  const logout = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.get("/saloon/logout", {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 204) {
        localStorage.removeItem("firestoreIdentifier")
        removeCookiePromise("authenticationToken")
          .then(() => {
            window.location.href = "/log-ind";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      else if (res.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      else if (res.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Incorrect Or Expired token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        removeCookiePromise("authenticationToken")
          .then(() => {
            window.location.href = "/log-ind";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "prøv venligst igen om nogle minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  const getCurrentTab = () => {
    const url = window.location.href;
    const segments = url.split('/');

    const baseIndex = segments.indexOf("bookingsystem");
    if (baseIndex !== -1 && baseIndex + 1 < segments.length) {
      return segments[baseIndex + 1];
    }
    return null;
  };

  // get the data of the dashboard on page load - Last 7 days
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/saloon/name", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        if (res.status === 200) {
          setSalonName(res.data.name)
        }
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isBold, setIsBold] = useState(getCurrentTab())
  return (
    <>
      <div className="Businesssidebar">
        <div style={{ width: "100%" }}>
          <img
            src={process.env.PUBLIC_URL + "/assets/Logo/green.png"}
            alt=""
            className="greencontainerTop"
          ></img>
        </div>
        <div className="Nav" >
          <Link to="/bookingsystem/kontrolpanel" className="Navitem" onClick={() => setIsBold("kontrolpanel")} style={isBold == "kontrolpanel" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>
            <Icon as={MdSpaceDashboard} className="Navicon" w={5} h={5} />{" "}
            Kontrolpanel
          </Link>
          <Link to="/bookingsystem/kalender" className="Navitem" onClick={() => setIsBold("kalender")} style={isBold == "kalender" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>
            <Icon as={MdCalendarToday} className="Navicon" w={5} h={5} />{" "}
            Kalender
          </Link>
          {/* <Link to="/bookingsystem/aftaler" className="Navitem" onClick={()=>setIsBold("aftaler")} style={isBold=="aftaler"?{fontWeight:"bold"}:{fontWeight:"normal"}}>
            <Icon as={MdEditCalendar} className="Navicon" w={5} h={5} /> Aftaler
          </Link> */}
          <Link
            to="/bookingsystem/provisioner"
            className="Navitem"
            onClick={() => setIsBold("provisioner")}
            style={isBold === "provisioner" ? { fontWeight: "bold" } : { fontWeight: "normal" }}
          >
            <Icon as={RiExchangeDollarLine} className="Navicon" w={5} h={5} /> Provisioner
          </Link>
          <Link to="/bookingsystem/tjenester" className="Navitem" onClick={() => setIsBold("tjenester")} style={isBold == "tjenester" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>
            <Icon as={MdMedicalServices} className="Navicon" w={5} h={5} />{" "}
            Tjenester
          </Link>
          <Link to="/bookingsystem/personale" className="Navitem" onClick={() => setIsBold("personale")} style={isBold == "personale" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>
            <Icon as={MdPersonAddAlt1} className="Navicon" w={5} h={5} />
            Personale
          </Link>
          <Link to="/bookingsystem/produkter" className="Navitem" onClick={() => setIsBold("produkter")} style={isBold == "produkter" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>
            <Icon as={BsBoxSeam} className="Navicon" w={5} h={5} />
            Produkter
          </Link>

          {/*<Link to="/bookingsystem/anmeldelser" className="Navitem" onClick={()=>setIsBold("anmeldelser")} style={isBold=="anmeldelser"?{fontWeight:"bold"}:{fontWeight:"normal"}}>*/}
          {/*  <Icon as={MdOutlineStarHalf} className="Navicon" w={5} h={5} />{" "}*/}
          {/*  Anmeldelser*/}
          {/*</Link>*/}
          <Link to="/bookingsystem/statistik" className="Navitem" onClick={() => setIsBold("statistik")} style={isBold == "statistik" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>
            <Icon as={MdQueryStats} className="Navicon" w={5} h={5} /> Statistik
          </Link>
          <Link
            to="/bookingsystem/indstillinger/sec/generelinformation"
            className="Navitem"
            onClick={() => setIsBold("indstillinger")} style={isBold == "indstillinger" ? { fontWeight: "bold" } : { fontWeight: "normal" }}
          >
            <Icon as={MdSettings} className="Navicon" w={5} h={5} />{" "}
            Indstillinger
          </Link>
          {/* <Link to="/bookingsystem/betaling" className="Navitem isDisabled" onClick={()=>setIsBold("betaling")} style={isBold=="betaling"?{fontWeight:"bold"}:{fontWeight:"normal"}}>
            <Icon as={MdCreditCard} className="Navicon" w={5} h={5} /> Betaling{" "}
            <span className="soon_tag">snart</span>
          </Link> */}
          {/* <Link to="/bookingsystem/opgradere" className="Navitem isDisabled">
            <Icon as={MdUpgrade} className="Navicon" w={5} h={5} /> Opgrader{" "}
            <span className="soon_tag">snart</span>
          </Link> */}

          <span className="Navitem" id="Logout" onClick={logout}>
            <Icon as={MdOutlineLogout} className="Navicon" w={5} h={5} /> Log ud
          </span>

        </div>

        <div className="bottomSidebar">


          <Link to="#">
            <img
              src={process.env.PUBLIC_URL + "/assets/Logo/A_logo.png"}
              alt=""
              className="Logocontainer"
            ></img>
            <Text width={"fit-content"} margin={"auto"} marginBottom={0} marginTop={1} fontWeight={"bold"} fontSize={"18px"}>By {salonName}</Text>
          </Link>
          <img
            src={process.env.PUBLIC_URL + "/assets/Logo/green.png"}
            alt=""
            className="greenContainerBottom"
          ></img>
        </div>
      </div>
    </>
  );
}

export default BusinessSideBar;
