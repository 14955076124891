import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Fergotpass from "./components/Fergotpass";
import Otp from "./components/Otp";
import Newpass from "./components/Newpass";
import Business from "./pages/Business";
import axios from "axios";
import { checkAuthentication } from "./middleware";
import MobilePage from "./pages/MobilePage";

axios.defaults.baseURL = "https://park-concept-api.bookinghero.io/api";
//axios.defaults.baseURL = "http://localhost:5000/api/";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.withCredentials = true;

Promise.all([checkAuthentication()]).then(([isAllowed]) => {
  const App = () => {
    const [isMobile, setIsMobile] = useState(false);

    // Check if the screen is mobile
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Set threshold for mobile devices
      };

      handleResize(); // Run on initial load
      window.addEventListener("resize", handleResize); // Listen for window resize

      return () => window.removeEventListener("resize", handleResize); // Cleanup listener
    }, []);

    if (isMobile) {
      return <MobilePage />; // Render MobilePage if mobile device
    }

    return (
      <BrowserRouter>
        <ChakraProvider>
          <Routes>
            <Route exact path="/fergotpassword" element={<Fergotpass />} />
            <Route
              exact
              path="/login/fergotpassword/otp"
              element={
                localStorage.getItem("forgotpasssaloon") ? <Otp /> : <Login />
              }
            />
            <Route
              exact
              path="/login/fergotpassword/reset"
              element={
                localStorage.getItem("forgotpasssaloon") ? (
                  <Newpass />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              exact
              path="/bookingsystem/*"
              element={isAllowed ? <Business /> : <Navigate to="/log-ind" />}
            />
            <Route
              exact
              path="/log-ind"
              element={
                isAllowed ? (
                  <Navigate to="/bookingsystem/kalender" />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              exact
              path="/"
              element={<Navigate to="/bookingsystem/kalender" />}
            />
          </Routes>
        </ChakraProvider>
      </BrowserRouter>
    );
  };

  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});
