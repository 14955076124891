import {
  ChevronLeft,
  Loader,
  Rows,
} from "lucide-react"


import { Button } from "../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"

import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table"
import { PlusCircle, Trash } from 'lucide-react'; // Using lucide-react for icons
import { Textarea } from "../components/ui/textarea"

import {

  TooltipProvider
} from "../components/ui/tooltip"
import { Link, useNavigate } from "react-router-dom"
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";


import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useEffect, useState } from "react"
import { Image, useToast } from "@chakra-ui/react"
import axios from "axios"
import Cookies from "js-cookie"

export const description =
  "A product edit page. The product edit page has a form to edit the product details, stock, product category, product status, and product images. The product edit page has a sidebar navigation and a main content area. The main content area has a form to edit the product details, stock, product category, product status, and product images. The sidebar navigation has links to product details, stock, product category, product status, and product images."

export default function AddProduct() {

  const [files, setFiles] = useState([])
  const removeImage = (indexToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };
  const toast = useToast()
  const [tags, setTags] = useState()




  // Get the tags
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/products/tags", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        setTags(res.data.tags)
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');


  // State to hold all rows that have been added
  const [rows, setRows] = useState([]);

  // State to hold the current input row before it is added
  const [currentRow, setCurrentRow] = useState({
    code: '',
    quantity: '',
    price: '',
    size: ''
  });



  const [stockskuBorder, setStockskuBorder] = useState(false)
  const [stockquantityBorder, setStockquantityBorder] = useState(false)
  const [stockpriceBorder, setStockpriceBorder] = useState(false)
  const [stocksizeBorder, setStocksizeBorder] = useState(false)
  // Function to handle input changes for the current row
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRow((prev) => ({
      ...prev,
      [name]: value
    }));
    setStockInfoBorder(false)
    setStockpriceBorder(false)
    setStockquantityBorder(false)
    setStockskuBorder(false)
    setStocksizeBorder(false)

  };
  // Function to handle adding a new variant row
  const addRow = () => {
if (currentRow.code === '') {
  setStockskuBorder(true)
  toast({
    title: "Manglende lager SKU",
    description: "Indtast lager SKU",
    position: "top-right",
    isClosable: true,
    status: "error",
  });
  return
}
if (!currentRow.quantity) {
  setStockquantityBorder(true)
  toast({
    title: "Manglende lagerkvantitet",
    description: "Indtast lagerkvantitet",
    position: "top-right",
    isClosable: true,
    status: "error",
  });
  return
}
if (!currentRow.price) {
  setStockpriceBorder(true)
  toast({
    title: "Manglende lagerpris",
    description: "Indtast lagerpris",
    position: "top-right",
    isClosable: true,
    status: "error",
  });
  return
}
if (!currentRow.size) {
  setStocksizeBorder(true)
  toast({
    title: "Manglende lagerstørrelse",
    description: "Indtast lagerstørrelse",
    position: "top-right",
    isClosable: true,
    status: "error",
  });
  return
}

    if (currentRow.code && currentRow.quantity && currentRow.price && currentRow.size) {
      // Add current filled row to the rows state
      setRows([...rows, currentRow]);
      // Reset the current row to empty inputs for a new entry
      setCurrentRow({ code: '', quantity: '', price: '', size: '' });
      setStockInfoBorder(false)
    }
  };


  // Function to handle deleting a row
  const deleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index)); // Remove the row at the specified index
    setStockInfoBorder(false)
  };


  // State variable to store the selected category (tag) ID
  const [selectedCategory, setSelectedCategory] = useState('');

  // Handle category selection
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setProductCategoryBorder(false)
  };


  // State variable to store the selected product status
  const [productStatus, setProductStatus] = useState('');

  // Handle status change
  const handleStatusChange = (value) => {
    setProductStatus(value);
    setProductStatusBorder(false)
  };


  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const [productNameBorder, setProductNameBorder] = useState(false)
  const [productDescriptionBorder, setProductDescriptionBorder] = useState(false)
  const [productCategoryBorder, setProductCategoryBorder] = useState(false)
  const [productStatusBorder, setProductStatusBorder] = useState(false)
  const [stockInfoBorder, setStockInfoBorder] = useState(false)
  const submitProduct = async (e) => {
    e.preventDefault();
    if (productName === "") {
      setProductNameBorder(true)
      toast({
        title: "Manglende produktnavn",
        description: "Indtast produktnavn",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      return
    }
    if (productDescription === "") {
      setProductDescriptionBorder(true)
      toast({
        title: "Manglende produktbeskrivelse",
        description: "Indtast produktbeskrivelse",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      return
    }
    if (rows.length === 0) {
      setStockInfoBorder(true)
      toast({
        title: "Manglende lageroplysninger",
        description: "Udfyld og gem mindst én lageroplysning",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      return
    }
    if (selectedCategory === "") {
      setProductCategoryBorder(true)
      toast({
        title: "Manglende produktkategori",
        description: "Vælg produktkategori",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      return
    }
    if (productStatus === "") {
      setProductStatusBorder(true)
      toast({
        title: "Manglende produktstatus",
        description: "Vælg produktstatus",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      return
    }
    if (files.length == 0) {
      toast({
        title: "Ingen billeder",
        description: "Indsæt mindst ét billede",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
      return
    }
    

    setIsLoading(true)
    const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dxg9wvfwv/image/upload';
    const uploadPreset = 'ml_default';

    try {
      const uploadedImages = await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData();
          formData.append('file', file.file); // file.file is the actual image data
          formData.append('upload_preset', uploadPreset);
          formData.append('folder', "Products");
          const response = await fetch(cloudinaryUrl, {
            method: "post",
            mode: "cors",
            body: formData
          });
          const json = await response.json();
          return {
            cloudinaryId: json.public_id,
            path: json.secure_url
          };
        })
      )


      // Construct the product object
      const product = {
        product: {
          name: productName,
          description: productDescription,
          categoryId: selectedCategory,
          status: productStatus
        },
        images: uploadedImages,
        SKUs: rows // Assuming rows is already an array of SKU objects
      };

      const res = await axios.post("/products", product, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });

      if (res.status === 201) {
        setIsLoading(false)
        navigate("/bookingsystem/produkter")
      }
      // Do something with the uploaded images, e.g., save them to your database
    } catch (err) {
      setIsLoading(false)
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  }

  return (
    <TooltipProvider>
      <div className="flex min-h-screen w-full flex-col bg-muted/40" style={{ background: "#edeadf" }}>
        <div className="flex flex-col ">

          <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
            <div className="mx-auto grid flex-1 auto-rows-max gap-4">
              <div className="flex items-center gap-4" style={{ position: "sticky", top: "0px", paddingTop: "10px", paddingBottom: "10px", background: "#edeadf" }}>
                <Link variant="outline" size="icon" className="h-7 w-7" to={"/bookingsystem/produkter"}>
                  <ChevronLeft className="h-4 w-4" />
                  <span className="sr-only">Back</span>
                </Link>
                <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
                  Tilføj Produkt
                </h1>

                <div className="ml-auto hidden items-center gap-2 md:ml-auto md:flex" >
                  <Button variant="outline" size="sm" style={{ marginRight: "10px", height: "40px" }}
                    onClick={() => navigate("/bookingsystem/produkter")}
                  >
                    Kassér
                  </Button>
                  <Button style={{ width: "120px", height: "40px" }} onClick={submitProduct}>
                    {isLoading ? (
                      <Loader className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                      'Gem Produkt'
                    )}

                  </Button>
                </div>
              </div>
              <div style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "18px", marginTop: "24px" }}>
                <div className="grid auto-rows-max items-start  col-span-8 gap-16">
                  <Card style={{ marginBottom: "16px" }}>
                    <CardHeader>
                      <CardTitle>Produktdetaljer</CardTitle>
                      <CardDescription>
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div className="grid gap-6">
                        <div className="grid gap-3">
                          <Label htmlFor="name">Navn</Label>
                          <Input
                            id="name"
                            type="text"
                            className="w-full"
                            value={productName}
                            onChange={(e) => { setProductName(e.target.value); setProductNameBorder(false) }}
                            style={productNameBorder ? { border: "red 2px solid" } : { border: "" }}
                            placeholder="Indtast produktnavn"
                          />
                        </div>
                        <div className="grid gap-3">
                          <Label htmlFor="description">Beskrivelse</Label>
                          <Textarea
                            id="description"
                            className="min-h-32"
                            value={productDescription}
                            onChange={(e) => { setProductDescription(e.target.value); setProductDescriptionBorder(false) }}
                            placeholder="Indtast produktbeskrivelse"
                            style={productDescriptionBorder ? { border: "red 2px solid" } : { border: "" }}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card style={{ marginBottom: "16px" }}>
                    <CardHeader>
                      <CardTitle>Lager</CardTitle>
                      <CardDescription>
                        Angiv oplysninger for forskellige produktprøver
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className="w-[100px]">SKU</TableHead>
                            <TableHead>Antal</TableHead>
                            <TableHead>Pris</TableHead>
                            <TableHead className="w-[100px]">Størrelse</TableHead>
                            <TableHead className="w-[50px]"></TableHead> {/* Empty head for delete button */}
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {/* Render all the rows that have been added */}
                          {rows.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className="font-semibold">
                                {row.code}

                              </TableCell>
                              <TableCell>
                                <Input
                                  type="number"
                                  value={row.quantity}
                                  readOnly
                                />
                              </TableCell>
                              <TableCell>
                                <Input
                                  type="number"
                                  value={row.price}
                                  readOnly
                                />
                              </TableCell>
                              <TableCell>
                                <Input
                                  type="number"
                                  value={row.size}
                                  readOnly
                                />
                              </TableCell>
                              <TableCell>
                                {/* Delete button for each added row */}
                                <Button
                                  size="sm"
                                  variant="ghost"
                                  onClick={() => deleteRow(index)}
                                  className="text-red-500"
                                >
                                  <Trash className="h-4 w-4" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}

                          {/* Empty row with placeholders, no trash icon */}
                          <TableRow>
                            <TableCell >
                              <Label htmlFor="code" className="sr-only">SKU</Label>
                              <Input
                                id="code"
                                name="code"
                                type="text"
                                value={currentRow.code}
                                onChange={handleInputChange}
                                placeholder="Indtast SKU"
                                style={(stockInfoBorder || stockskuBorder) ? { border: "red 2px solid" } : { border: "" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Label htmlFor="quantity" className="sr-only">Antal</Label>
                              <Input
                                id="quantity"
                                name="quantity"
                                type="number"
                                value={currentRow.quantity}
                                onChange={handleInputChange}
                                placeholder="Indtast antal"
                                style={stockInfoBorder || stockquantityBorder ? { border: "red 2px solid" } : { border: "" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Label htmlFor="price" className="sr-only">Pris</Label>
                              <Input
                                id="price"
                                name="price"
                                type="number"
                                value={currentRow.price}
                                onChange={handleInputChange}
                                placeholder="Indtast pris"
                                style={stockInfoBorder || stockpriceBorder ? { border: "red 2px solid" } : { border: "" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Label htmlFor="size" className="sr-only">Størrelse</Label>
                              <Input
                                id="size"
                                name="size"
                                type="number"
                                value={currentRow.size}
                                onChange={handleInputChange}
                                placeholder="Indtast størrelse"
                                style={stockInfoBorder || stocksizeBorder ? { border: "red 2px solid" } : { border: "" }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                    <CardFooter className="justify-center border-t p-4">
                      {/* Add Variant button */}
                      <Button
                        size="sm"
                        variant="ghost"
                        className="gap-1"
                        onClick={addRow}
                      >
                        <PlusCircle className="h-3.5 w-3.5" />
                        Gem Variant
                      </Button>
                    </CardFooter>
                  </Card>
                  <Card style={{ marginBottom: "16px" }}>
                    <CardHeader>
                      <CardTitle>Produktkategori</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div>
                        <Label htmlFor="category" style={{ display: "block", marginBottom: "10px" }}>
                          Kategori
                        </Label>
                        <Select onValueChange={handleCategoryChange}
                        >
                          <SelectTrigger
                            id="category"
                            aria-label="Select category"
                            style={productCategoryBorder ? { border: "red 2px solid", width: "50%" } : { width: "50%" }}
                          >
                            <SelectValue placeholder="Vælg kategori" />
                          </SelectTrigger>
                          <SelectContent>
                            {tags && tags.map((tag) => (
                              <SelectItem key={tag.id} value={tag.id}>
                                {tag.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </CardContent>
                  </Card>
                </div>
                <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
                  <Card style={{ marginBottom: "16px" }}>
                    <CardHeader>
                      <CardTitle>Produktstatus</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="grid gap-6">
                        <div className="grid gap-3">
                          <Label htmlFor="status">Status</Label>
                          <Select onValueChange={handleStatusChange}>
                            <SelectTrigger id="status" aria-label="Select status" style={productStatusBorder ? { border: "red 2px solid" } : { border: "" }}
                            >
                              <SelectValue placeholder="Vælg status" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="draft">Kladde</SelectItem>
                              <SelectItem value="active">Aktiv</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="overflow-hidden" x-chunk="dashboard-07-chunk-4" style={{ marginBottom: "16px" }}  >
                    <CardHeader>
                      <CardTitle>Produktfotografier</CardTitle>
                      <CardDescription>
                      Inkluder mindst ét billede af høj kvalitet af produktet
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div className="grid gap-2">
                        {/* Main image */}
                        <div style={{ position: "relative", width: "380px", height: "300px", marginBottom: "10px" }}>
                          <Image
                            alt="Main Product Image"
                            className="aspect-square rounded-md object-cover"
                            height="100%"
                            src={files[0] ? URL.createObjectURL(files[0].file) : "/assets/placeHolder/saloon.jpeg"}
                            width="100%"
                            objectFit={"cover"}
                            margin={"auto"}
                          />
                          {files[0] && (
                            <button
                              onClick={() => removeImage(0)}
                              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                              style={{ position: "absolute", top: "10px", right: "10px", color: "white", background: "black", opacity: "60%", borderRadius: "50%", width: "25px", height: "25px", paddingTop: "0" }}
                            >
                              x
                            </button>
                          )}
                        </div>

                        {/* Thumbnails and Upload */}
                        <div className="flex">
                          {/* Thumbnails */}
                          {[1, 2].map((index) => (
                            <div key={index} style={{ position: "relative", marginRight: "15px", marginBottom: "3px" }}>
                              <button>
                                <Image
                                  alt={`Thumbnail ${index + 1}`}
                                  className="aspect-square rounded-md "
                                  height="100px"
                                  src={files[index] ? URL.createObjectURL(files[index].file) : "/assets/placeHolder/saloon.jpeg"}
                                  width="120px"
                                />
                              </button>
                              {files[index] &&
                                <button
                                  onClick={() => removeImage(index)}
                                  style={{ position: "absolute", top: "5px", right: "5px", color: "white", background: "black", opacity: "60%", borderRadius: "50%", width: "20px", height: "20px", paddingTop: "0", fontSize: "12px" }}
                                >
                                  x
                                </button>
                              }
                            </div>
                          ))}


                        </div>
                        {/* Upload Button */}
                        {files.length < 3 ?
                          <FilePond
                            files={files}
                            onupdatefiles={setFiles}
                            allowMultiple={true}
                            maxFiles={3}
                            name="files"
                            labelIdle="Upload"
                            allowRemove={false} // Disable the remove button for each file
                            allowRevert={false}  // Disables the refresh/revert button
                            instantUpload={false}  // Prevents automatic file upload, ensuring no revert button appears
                            allowProcess={false}
                            allowImagePreview={false}  // Disables image previews in FilePond
                            // Disables the display of file names and sizes
                            itemInsertLocation="after" // Avoids any rendering of items that are uploaded
                            onpreparefile={(file) => {
                              // Avoid rendering names or sizes
                              return {
                                file: file,
                                metadata: {
                                  name: '', // Set an empty name
                                  size: 0 // Set a size of 0
                                }
                              };
                            }}
                          /> :
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", height: "85px", background: "#f1f0ef", borderRadius: "5%", marginBottom: "15px", fontSize: "14px" }}>Maksimalt 3 billeder tilladt</div>
                        }
                      </div>
                    </CardContent>
                  </Card>


                </div>
              </div>
              {/* <div className="flex items-center justify-center gap-2 md:hidden">
                <Button variant="outline" size="sm">
                  Discard
                </Button>
                <Button size="sm">Save Product</Button>
              </div> */}
            </div>
          </main>
        </div>
      </div>
    </TooltipProvider>
  )
}
