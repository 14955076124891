import React from "react";
import "./Loginright.css";

function Loginright() {
  return (
    <>
      <div className="Loginright">
        <div className="Imagecontainer">
          <div className="Headerscontainer">
           <img src="/assets/Logo/logo_park_white.webp" alt="" className="logoWhite"/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loginright;
