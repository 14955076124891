import {
  AddIcon,
  DeleteIcon,
  DragHandleIcon,
  EditIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  IconButton,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Services.css";
import Cookies from "js-cookie"
function Services() {
  // store data from sub service modal inputs
  const [myinputs, setInputs] = useState({
    subservicename: "",
    subserviceprice: "",
    subservicetime: "",
    subservicedescription: "",
  });

  //refresh list everytime listitem is added
  const [refreshKey, setRefreshKey] = useState(0);

  // declare variables for inputs data
  const [servicename, setServicename] = useState("");

  // handle getting the data from the inputs for the service
  const handlechangeservice = (e) => {
    setServicename({ [e.target.name]: e.target.value });
  };

  // handle getting the data from the inputs
  const handlechangeSubservice = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle opening and closing the service modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();

  // handle closing and opening up the service alert dialogue
  const {
    isOpen: isServiceDeleteOpen,
    onOpen: onServiceDeleteOpen,
    onClose: onServiceDeleteClose,
  } = useDisclosure();
  const cancelRef = React.useRef();

  // handle closing and opening up the subservices modal
  const {
    isOpen: isSubServiceOpen,
    onOpen: onSubServiceOpen,
    onClose: onSubServiceClose,
  } = useDisclosure();

  // handle closing and opening up the Subservice alert dialogue
  const {
    isOpen: isSubServiceDeleteOpen,
    onOpen: onSubServiceDeleteOpen,
    onClose: onSubServiceDeleteClose,
  } = useDisclosure();

  // handle closing and opening up the update subservices modal
  const {
    isOpen: isUpdateSubServiceOpen,
    onOpen: onUpdateSubServiceOpen,
    onClose: onUpdateSubServiceClose,
  } = useDisclosure();

  // declare toast for notifications
  const toast = useToast();

  // get all the services and store them
  const [services, setServices] = useState([]);

  // get id of present service
  const [serviceid, setServiceid] = useState("");

  // get all the services on page load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/service", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization":Cookies.get("authenticationToken")
          }
        });
        setServices(res.data);
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // handle adding a new service
  const handleSubmitService = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/service/", servicename, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        onClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Service oprettet.",
          description: "Du kan nu tilføje underservice.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle updating a new service
  const handleUpdateService = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(`/service/${serviceid}`, servicename, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        onUpdateClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Service opdateret.",
          description: "Du kan nu tilføje underservice.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle deleting a service
  const handleDeleteService = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.delete(`/service/${serviceid}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        onServiceDeleteClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Service slettet.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle adding a subservice
  const handleSubmitSubService = async (e) => {
    e.preventDefault();
    try {
      const subservicename = myinputs.subservicename;
      const subserviceprice = myinputs.subserviceprice;
      const subservicetime = myinputs.subservicetime;
      const subservicedescription = myinputs.subservicedescription;
      const res = await axios.post("/subservice/", {
        subservicename,
        subserviceprice,
        subservicetime,
        subservicedescription,
        serviceid,
      }, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        onSubServiceClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Underservice oprettet.",
          description: "Du kan nu tilføje medarbejdere til den.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle deleting a subservice
  const handleDeleteSubService = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.delete(`/subservice/${serviceid}`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        onSubServiceDeleteClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Underservice slettet.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // handle updating a subservice
  const handleUpdateSubService = async (e) => {
    e.preventDefault();
    try {
      const subservicename = myinputs.subservicename;
      const subserviceprice = myinputs.subserviceprice;
      const subservicetime = myinputs.subservicetime;
      const subservicedescription = myinputs.subservicedescription;
      const res = await axios.put(`/subservice/${serviceid}`, {
        subservicename,
        subserviceprice,
        subservicetime,
        subservicedescription,
      }, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        onUpdateSubServiceClose();
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Underservice opdateret.",
          description: "Du kan nu tilføje medarbejdere til den.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="Services">
        <div className="Header">Administrer dine service</div>
        <div className="Addservicecontainer">
          <Button className="Addservicebtn" onClick={onOpen}>
            Tilføj service +
          </Button>
        </div>
        {services.length > 0 ? (
          <>
            <div className="Servicelistcontainer">
              {services.map((service) => (
                <Accordion allowMultiple className="Accordion" key={service.id}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          flex="1"
                          textAlign="left"
                          className="Accordionheader"
                        >
                          {service.name}
                        </Box>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<DragHandleIcon />}
                            variant="outline"
                            className="Accordiontbn"
                          />
                          <Portal>
                            <MenuList>
                              <MenuItem
                                icon={<EditIcon />}
                                onClick={() => {
                                  setServicename(service.name);
                                  setServiceid(service.id);
                                  onUpdateOpen();
                                }}
                              >
                                Redigere
                              </MenuItem>
                              <MenuItem
                                icon={<DeleteIcon />}
                                onClick={() => {
                                  setServiceid(service.id);
                                  onServiceDeleteOpen();
                                }}
                              >
                                Slet
                              </MenuItem>
                              <MenuItem
                                icon={<AddIcon />}
                                onClick={() => {
                                  setServiceid(service.id);
                                  onSubServiceOpen();
                                }}
                              >
                                Tilføj underservice
                              </MenuItem>
                            </MenuList>
                          </Portal>
                        </Menu>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {service.subservices.map((subservice) => (
                        <>
                          <div className="Panelitem" key={subservice.id}>
                            <div className="Panelheading">
                              <div className="Heading">{subservice.name}</div>
                              <div className="Icon">
                                <div>
                                  <EditIcon
                                    className="Panelicon"
                                    onClick={() => {
                                      setServiceid(subservice.id);
                                      setInputs((prevState) => {
                                        return {
                                          ...prevState,
                                          subservicename: subservice.name,
                                          subserviceprice: subservice.price,
                                          subservicedescription:
                                            subservice.description,
                                          subservicetime: subservice.time,
                                        };
                                      });
                                      onUpdateSubServiceOpen();
                                    }}
                                  />
                                </div>
                                <div>
                                  <DeleteIcon
                                    className="Panelicon"
                                    onClick={() => {
                                      setServiceid(subservice.id);
                                      onSubServiceDeleteOpen();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="Paneldetails">
                              {subservice.time} min - {subservice.price} Dkk
                            </div>
                          </div>
                          <Divider className="Devider" />
                        </>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="Emptylistcontainer">
              <InfoOutlineIcon className="Emptyicon" />
              <div className="Emptyheader">Ingen service endnu !</div>
              <div className="Emptytext">
                Når du tilføjer service, vises de her
              </div>
            </div>
          </>
        )}
      </div>
      {/* ADD SERVICE MODAL */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form>
            <ModalHeader>Tilføj Service</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired>
                <FormLabel>Service navn</FormLabel>
                <Input
                  name="servicename"
                  variant="filled"
                  onChange={handlechangeservice}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                className="ServiceConfirmbtn"
                variant="solid"
                type="submit"
                onClick={handleSubmitService}
              >
                Bekræfte
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* UPDATE SERVICE MODAL */}
      <Modal isOpen={isUpdateOpen} onClose={onUpdateClose}>
        <ModalOverlay />
        <ModalContent>
          <form>
            <ModalHeader>Opdatere service</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired>
                <FormLabel>Service navn</FormLabel>
                <Input
                  name="servicename"
                  variant="filled"
                  defaultValue={servicename}
                  onChange={handlechangeservice}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                className="ServiceConfirmbtn"
                variant="solid"
                type="submit"
                onClick={handleUpdateService}
              >
                Bekræfte
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* ALERT DIALOGUE TO DELETE SERVICE */}
      <AlertDialog
        isOpen={isServiceDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onServiceDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet service
            </AlertDialogHeader>

            <AlertDialogBody>
              Er du sikker? Du kan ikke fortryde denne handling bagefter.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onServiceDeleteClose}>
                Anullere
              </Button>
              <Button colorScheme="red" onClick={handleDeleteService} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* ADD SUB SERVICE MODAL */}
      <Modal isOpen={isSubServiceOpen} onClose={onSubServiceClose}>
        <ModalOverlay />
        <ModalContent>
          <form>
            <ModalHeader>Tilføj underservice</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired className="Subserviceinput">
                <FormLabel>underservice</FormLabel>
                <Input
                  name="subservicename"
                  variant="filled"
                  onChange={handlechangeSubservice}
                />
              </FormControl>
              <div className="Subserviceinputdevider">
                <FormControl isRequired className="Subserviceinput">
                  <FormLabel>Pris (dkk)</FormLabel>
                  <Input
                    type="number"
                    name="subserviceprice"
                    placeholder="10"
                    variant="filled"
                    onChange={handlechangeSubservice}
                  />
                </FormControl>
                <FormControl isRequired className="Subserviceinput">
                  <FormLabel>Tid (min)</FormLabel>
                  <Input
                    type="number"
                    name="subservicetime"
                    placeholder="30"
                    variant="filled"
                    onChange={handlechangeSubservice}
                  />
                </FormControl>
              </div>
              <FormControl isRequired className="Subserviceinput">
                <FormLabel>Beskrivelse</FormLabel>
                <Textarea
                  name="subservicedescription"
                  placeholder="Skriv her en kort beskrivelse af din service"
                  variant="filled"
                  onChange={handlechangeSubservice}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                className="ServiceConfirmbtn"
                variant="solid"
                type="submit"
                onClick={handleSubmitSubService}
              >
                Bekræfte
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* ALERT DIALOGUE TO DELETE SUB SERVICE */}
      <AlertDialog
        isOpen={isSubServiceDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onSubServiceDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet underservice
            </AlertDialogHeader>

            <AlertDialogBody>
              Er du sikker? Du kan ikke fortryde denne handling bagefter.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onSubServiceDeleteClose}>
                anullere
              </Button>
              <Button colorScheme="red" onClick={handleDeleteSubService} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* UPDATE SUB SERVICE MODAL */}
      <Modal isOpen={isUpdateSubServiceOpen} onClose={onUpdateSubServiceClose}>
        <ModalOverlay />
        <ModalContent>
          <form>
            <ModalHeader>Opdater underservice</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired className="Subserviceinput">
                <FormLabel>Navn på underservice</FormLabel>
                <Input
                  name="subservicename"
                  variant="filled"
                  defaultValue={myinputs.subservicename}
                  onChange={handlechangeSubservice}
                />
              </FormControl>
              <div className="Subserviceinputdevider">
                <FormControl isRequired className="Subserviceinput">
                  <FormLabel>Pris (dkk)</FormLabel>
                  <Input
                    type="number"
                    name="subserviceprice"
                    placeholder="10"
                    variant="filled"
                    defaultValue={myinputs.subserviceprice}
                    onChange={handlechangeSubservice}
                  />
                </FormControl>
                <FormControl isRequired className="Subserviceinput">
                  <FormLabel>Tid (min)</FormLabel>
                  <Input
                    type="number"
                    name="subservicetime"
                    placeholder="30"
                    variant="filled"
                    defaultValue={myinputs.subservicetime}
                    onChange={handlechangeSubservice}
                  />
                </FormControl>
              </div>
              <FormControl isRequired className="Subserviceinput">
                <FormLabel>Beskrivelse</FormLabel>
                <Textarea
                  name="subservicedescription"
                  placeholder="Skriv her en kort beskrivelse af din service"
                  variant="filled"
                  defaultValue={myinputs.subservicedescription}
                  onChange={handlechangeSubservice}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                className="ServiceConfirmbtn"
                variant="solid"
                type="submit"
                onClick={handleUpdateSubService}
              >
                Bekræfte
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Services;
