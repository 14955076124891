import {
  File,
  ListFilter,
  MoreHorizontal,
  PlusCircle,
  View,
} from "lucide-react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import { Badge } from "../components/ui/badge";
import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardFooter } from "../components/ui/card";
import { FaEdit, FaListUl, FaRegTrashAlt } from "react-icons/fa";
import { BsGrid3X3GapFill } from "react-icons/bs";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Input } from "../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Cookies from "js-cookie";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

export default function Products() {
  const [selectedSkus, setSelectedSkus] = useState({});
  const [selectedTag, setSelectedTag] = useState({id: 0, name: "Alle kategorier"});
  const [products, setProducts] = useState([]);
  const [tags, setTags] = useState([]);

  const navigate = useNavigate();

  const handleSkuSelect = (productId, skuId) => {
    setSelectedSkus((prev) => ({
      ...prev,
      [productId]: skuId,
    }));
  };

  function formatDateTime(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const toast = useToast();
  const [filters, setFilters] = useState({
    type: "all",
    search: "_",
    sort: "price",
    page: 1,
  });
  const [view, setView] = useState("grid");
  const [pages, setPages] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);

  const handleTabChange = (type) => {
    setFilters((prev) => ({
      ...prev,
      type,
    }));
    handlePageChange(1);
  };

  const handleViewChange = (view) => {
    setView(view);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    setFilters((prev) => ({
      ...prev,
      search: value === "" ? "_" : value,
    }));
    handlePageChange(1);
  };

  const handleSortChange = (sortKey) => {
    setFilters((prev) => ({
      ...prev,
      sort: sortKey,
    }));
    handlePageChange(1);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= pages) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: page,
      }));
    }
  };

  const [refreshKey, setRefreshKey] = useState(true);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const res = await axios.get('/products/tags', {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        });
        if (res.data.success) {
          setTags([{id: 0, name: "Alle kategorier"}, ...res.data.tags]);
        }
      } catch (err) {
        // Handle errors
        toast({
          title: "Error fetching tags",
          description: "Could not load tags from the server.",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    fetchTags();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `/products/${filters.type}/${filters.search}/${selectedTag.id}/${filters.page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: Cookies.get("authenticationToken"),
            },
          }
        );
        setProducts(res.data.products);
        setPages(res.data.totalPages);
        setTotalProducts(res.data.totalNumProducts);
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
  }, [filters, refreshKey, selectedTag]);

  const handleDeleteProduct = async (id) => {
    try {
      const res = await axios.delete(`/products/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("authenticationToken"),
        },
      });
      if (res.status === 204) {
        setRefreshKey(!refreshKey);
        toast({
          title: "Produktet er slettet.",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  async function getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  async function exportToPdf() {
    const doc = new jsPDF();
    const imageSize = 20;

    const tableBody = await Promise.all(
      products.map(async (product) => {
        const imageBase64 = await getBase64ImageFromUrl(product.image_path);
        return [
          product.name,
          product.status,
          product.price,
          product.quantity,
          formatDateTime(product.created_at),
          imageBase64,
        ];
      })
    );

    doc.autoTable({
      head: [
        ["Navn", "Status", "Pris", "Mængde", "Oprettelsesdato", "Billede"],
      ],
      body: tableBody.map(
        ([name, status, price, quantity, createdAt, imageBase64]) => [
          name,
          status,
          price,
          quantity,
          createdAt,
          "",
        ]
      ),
      startY: 10,
      rowPageBreak: "auto",
      didDrawCell: function (data) {
        if (data.column.index === 5 && data.cell.section === "body") {
          const imageBase64 = tableBody[data.row.index][5];
          if (imageBase64) {
            doc.addImage(
              imageBase64,
              "JPEG",
              data.cell.x + 2,
              data.cell.y + 2,
              imageSize,
              imageSize
            );
          }
        }
      },
      styles: {
        cellPadding: 2,
      },
      headStyles: {
        minCellHeight: 10,
        valign: "middle",
      },
      bodyStyles: {
        minCellHeight: imageSize + 4,
        valign: "middle",
      },
    });

    doc.save("table_products.pdf");
  }

  return (
    <div
      className="Products flex min-h-screen w-full flex-col bg-muted/40"
      style={{
        height: "100vh",
        backgroundColor: "#edeadf",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          fontWeight: "bolder",
          fontSize: "larger",
          paddingTop: "1.5rem",
          paddingLeft: "1rem",
        }}
      >
        Produkter
      </div>
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <Tabs defaultValue="all">
            <div className="flex items-center">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <Flex
                  style={{
                    background: "#f1f5f9",
                    borderRadius: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => handleViewChange("list")}
                    style={{
                      background: view === "list" ? "white" : "transparent",
                      color: view === "list" ? "black" : "gray",
                      height: "85%",
                      boxShadow:
                        view === "list"
                          ? "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                          : "",
                      marginRight: "0.3rem",
                      marginLeft: "3px",
                    }}
                  >
                    <FaListUl />
                  </Button>

                  <Button
                    onClick={() => handleViewChange("grid")}
                    style={{
                      background: view === "grid" ? "white" : "transparent",
                      color: view === "grid" ? "black" : "gray",
                      height: "85%",
                      boxShadow:
                        view === "grid"
                          ? "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                          : "",
                      marginRight: "3px",
                    }}
                  >
                    <BsGrid3X3GapFill />
                  </Button>
                </Flex>

                <TabsList>
                  <TabsTrigger
                    value="all"
                    onClick={() => handleTabChange("all")}
                    selected={filters.type === "all"}
                  >
                    Alle
                  </TabsTrigger>

                  <TabsTrigger
                    value="active"
                    onClick={() => handleTabChange("active")}
                    selected={filters.type === "active"}
                  >
                    Aktiv
                  </TabsTrigger>
                  <TabsTrigger
                    value="draft"
                    onClick={() => handleTabChange("draft")}
                    selected={filters.type === "draft"}
                  >
                    Kladde
                  </TabsTrigger>
                </TabsList>
              </div>
              <div className="ml-auto flex items-center gap-2">
                <div
                  className="relative ml-auto flex-1 md:grow-0"
                  style={{ marginRight: "10px" }}
                >
                  <Input
                    type="search"
                    placeholder="Søg..."
                    className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                    value={filters.search === "_" ? "" : filters.search}
                    onChange={handleSearchChange}
                  />
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="h-8 gap-1" style={{ marginRight: "10px" }}>
                      {selectedTag.name}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Vælg en kategori</DropdownMenuLabel>
                    {tags.map((tag) => (
                      <DropdownMenuItem key={tag.id} onClick={() => setSelectedTag(tag)}>
                        {tag.name}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
                <div style={{ marginRight: "3px" }}></div>
                <Button
                  variant="outline"
                  className="h-8 gap-1"
                  style={{ marginRight: "10px" }}
                  onClick={exportToPdf}
                >
                  <File className="h-3.5 w-3.5" />
                  <span className="not-sr-only whitespace-nowrap">Eksport</span>
                </Button>
                <div style={{ marginRight: "3px" }}></div>
                <Button
                  className="h-8 gap-1"
                  onClick={() => navigate("/bookingsystem/produkter/tilføj")}
                  hidden={true}
                >
                  <PlusCircle className="h-3.5 w-3.5" />
                  <span className="not-sr-only whitespace-nowrap">
                    Tilføj Produkt
                  </span>
                </Button>
              </div>
            </div>
            {products.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="Emptylistcontainer">
                  <InfoOutlineIcon className="Emptyicon" />
                  <div className="Emptyheader">Tom produktliste</div>
                  <div className="Emptytext">
                    Der er ingen produkter, der opfylder din forespørgsel
                  </div>
                </div>
              </div>
            ) : (
              <TabsContent value={filters.type}>
                {view === "list" ? (
                  <Card x-chunk="dashboard-06-chunk-0">
                    <CardContent>
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className="hidden w-[100px] sm:table-cell">
                              <span className="sr-only">Billede</span>
                            </TableHead>
                            <TableHead>Navn</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead className="hidden md:table-cell">
                              Pris
                            </TableHead>
                            <TableHead className="hidden md:table-cell">
                              Mængde
                            </TableHead>
                            <TableHead className="hidden md:table-cell">
                              Oprettet den
                            </TableHead>
                            <TableHead>
                              <span className="sr-only">Handlinger</span>
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {products.map((product) => (
                            <TableRow key={product.id}>
                              <TableCell className="hidden sm:table-cell">
                                <Image
                                  src={
                                    product.image_path ||
                                    "https://via.placeholder.com/64"
                                  }
                                  alt="Product image"
                                  w="40px"
                                />
                              </TableCell>
                              <TableCell className="font-medium">
                                {product.name}
                              </TableCell>
                              <TableCell>
                                <Badge variant="outline">
                                  {product.status === "active"
                                    ? "Aktiv"
                                    : "Kladde"}
                                </Badge>
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                fra {product.price} Kr
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                {product.quantity}
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                {formatDateTime(product.created_at)}
                              </TableCell>
                              <TableCell>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button
                                      aria-haspopup="true"
                                      size="icon"
                                      variant="ghost"
                                    >
                                      <MoreHorizontal className="h-4 w-4" />
                                      <span className="sr-only">
                                        Skift menu
                                      </span>
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent align="end">
                                    <DropdownMenuLabel>
                                      Handlinger
                                    </DropdownMenuLabel>
                                    <DropdownMenuItem
                                      onClick={() =>
                                        navigate(
                                          `/bookingsystem/produkter/rediger/${product.id}`
                                        )
                                      }
                                    >
                                      Rediger
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      onClick={() =>
                                        handleDeleteProduct(product.id)
                                      }
                                    >
                                      Slet
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                    <CardFooter>
                      <Flex
                        justifyContent="space-between"
                        w="full"
                        direction={"column"}
                      >
                        <Text className="text-xs text-muted-foreground">
                          Viser{" "}
                          <strong>
                            {(filters.page - 1) * 10 + 1}-
                            {(filters.page - 1) * 10 + products.length}
                          </strong>{" "}
                          af <strong>{totalProducts}</strong> Produkter
                        </Text>
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          gap={2}
                        >
                          <IconButton
                            icon={<ChevronLeftIcon />}
                            onClick={() => handlePageChange(filters.page - 1)}
                            disabled={filters.page === 1}
                            aria-label="Previous Page"
                            size="md"
                            borderRadius="md"
                          />
                          {[...Array(pages)].map((_, index) => {
                            const pageNumber = index + 1;
                            return (
                              <Button
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                variant={
                                  filters.page === pageNumber
                                    ? "solid"
                                    : "outline"
                                }
                                colorScheme="primary"
                                size="sm"
                              >
                                {pageNumber}
                              </Button>
                            );
                          })}
                          <IconButton
                            icon={
                              <ChevronLeftIcon transform="rotate(180deg)" />
                            }
                            onClick={() => handlePageChange(filters.page + 1)}
                            disabled={filters.page === pages}
                            aria-label="Next Page"
                            size="md"
                            borderRadius="md"
                          />
                        </Flex>
                      </Flex>
                    </CardFooter>
                  </Card>
                ) : (
                  <Box pt={"4"}>
                    <Grid
                      templateColumns={{
                        base: "1fr",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(4, 1fr)",
                      }}
                      gap={8}
                    >
                      {products.map((product) => {
                        const selectedSku =
                          product.skus.find(
                            (sku) => sku.skuId === selectedSkus[product.id]
                          ) || product.skus[0];

                        return (
                          <GridItem
                            key={product.id}
                            bg={"white"}
                            p={5}
                            rounded="md"
                            display="flex"
                            flexDirection="column" // Make GridItem a flex container
                            justifyContent="space-between" // Ensure children are spaced
                          >
                            <Image
                              src={
                                product.image_path ||
                                "https://via.placeholder.com/250"
                              }
                              alt={`${product.name} image`}
                              borderRadius="md"
                              boxShadow="sm"
                              w={"100%"}
                            />
                            <Flex mt={4} color={"gray"} gap={4}>
                              {product.skus.map((sku) => (
                                <Button
                                  key={sku.skuId}
                                  variant="outline"
                                  onClick={() =>
                                    handleSkuSelect(product.id, sku.skuId)
                                  }
                                  style={{
                                    fontSize: "1rem",
                                    padding: "1px 4px",
                                    height: "fit-content",
                                    border:
                                      selectedSku.skuId === sku.skuId
                                        ? "1.5px solid black"
                                        : "1.5px solid #CCCCCC",
                                    backgroundColor: "transparent",
                                    color: "black",
                                    borderRadius: "0",
                                  }}
                                >
                                  {sku.size}ml
                                </Button>
                              ))}
                            </Flex>
                            <Text fontSize="md" fontWeight={"bold"} mt={4}>
                              {product.name}
                            </Text>
                            <Text
                              fontSize="xs"
                              color={"gray.500"}
                              maxW={{
                                base: "100%",
                                sm: "260px",
                                lg: "260px",
                              }}
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {product.description}
                            </Text>
                            <Flex bottom={5} mt={6}>
                              <Flex
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                width={"100%"}
                              >
                                <Text color={"red.500"} fontWeight={"bold"}>
                                  {` ${
                                    product.skus.length > 1 ? "Fra " : ""
                                  } ${selectedSku.price} Kr.`}
                                </Text>
                                <Text fontWeight={"bold"}>
                                  Mængde:{" "}
                                  {selectedSku
                                    ? selectedSku.quantity
                                    : product.quantity}
                                </Text>
                              </Flex>
                              <Flex
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                gap={1}
                                hidden={true}
                              >
                                <IconButton
                                  icon={<FaEdit />}
                                  aria-label="Edit Product"
                                  onClick={() =>
                                    navigate(
                                      `/bookingsystem/produkter/rediger/${product.id}`
                                    )
                                  }
                                  colorScheme="blue"
                                  size="sm"
                                  display={"none"}
                                />
                                <IconButton
                                  icon={<FaRegTrashAlt />}
                                  aria-label="Delete Product"
                                  onClick={() =>
                                    handleDeleteProduct(product.id)
                                  }
                                  colorScheme="red"
                                  size="sm"
                                  display={"none"}
                                />
                              </Flex>
                            </Flex>
                          </GridItem>
                        );
                      })}
                    </Grid>
                    <Box
                      gap={2}
                      mt={4}
                      bgColor={"white"}
                      p={2}
                      rounded={"md"}
                      w={"fit-content"}
                    >
                      <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={() => handlePageChange(filters.page - 1)}
                        disabled={filters.page === 1}
                        aria-label="Previous Page"
                        size="md"
                        borderRadius="md"
                      />
                      {[...Array(pages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          <Button
                            key={pageNumber}
                            onClick={() => handlePageChange(pageNumber)}
                            variant={
                              filters.page === pageNumber ? "solid" : "outline"
                            }
                            colorScheme="primary"
                            size="sm"
                          >
                            {pageNumber}
                          </Button>
                        );
                      })}
                      <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={() => handlePageChange(filters.page + 1)}
                        disabled={filters.page === pages}
                        aria-label="Next Page"
                        size="md"
                        borderRadius="md"
                      />
                    </Box>
                  </Box>
                )}
              </TabsContent>
            )}
          </Tabs>
        </main>
      </div>
    </div>
  );
}
