import { DeleteIcon, EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Button, Image, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Album.css";
import Cookies from "js-cookie"
function Album() {
  const [image1, setimage1] = useState();
  const [image2, setimage2] = useState();
  const [image3, setimage3] = useState();
  const [image4, setimage4] = useState();
  const [image5, setimage5] = useState();
  const [currentCloudinaryId1, setCurrentCloudinaryId1] = useState()
  const [currentImageLink1, setCurrentImageLink1] = useState()
  const [oldClouadinaryId1, setOldClouadinaryId1] = useState()
  //get referance to first image uploader
  const hiddenFileInput1 = React.useRef(null);

  const [file1, setFile1] = useState(null); // For storing the file to be uploaded
  const handleimage1Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile1(file); // Store file for later Cloudinary upload
      const reader = new FileReader();
      reader.onloadend = () => {
        setimage1(reader.result); // Show image preview
      };
      reader.readAsDataURL(file);
    }
  };
  // clear image 1
  // Clear the image
  const clearimage1 = () => {
    setimage1(null);
    setFile1(null);
    hiddenFileInput1.current.value = null;
  };

  const [currentCloudinaryId2, setCurrentCloudinaryId2] = useState()
  const [currentImageLink2, setCurrentImageLink2] = useState()
  const [oldClouadinaryId2, setOldClouadinaryId2] = useState()
  //get referance to first image uploader
  const hiddenFileInput2 = React.useRef(null);

  const [file2, setFile2] = useState(null); // For storing the file to be uploaded
  const handleimage2Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile2(file); // Store file for later Cloudinary upload
      const reader = new FileReader();
      reader.onloadend = () => {
        setimage2(reader.result); // Show image preview
      };
      reader.readAsDataURL(file);
    }
  };
  // clear image 2
  // Clear the image
  const clearimage2 = () => {
    setimage2(null);
    setFile2(null);
    hiddenFileInput2.current.value = null;
  };

  const [currentCloudinaryId3, setCurrentCloudinaryId3] = useState()
  const [currentImageLink3, setCurrentImageLink3] = useState()
  const [oldClouadinaryId3, setOldClouadinaryId3] = useState()
  //get referance to first image uploader
  const hiddenFileInput3 = React.useRef(null);

  const [file3, setFile3] = useState(null); // For storing the file to be uploaded
  const handleimage3Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile3(file); // Store file for later Cloudinary upload
      const reader = new FileReader();
      reader.onloadend = () => {
        setimage3(reader.result); // Show image preview
      };
      reader.readAsDataURL(file);
    }
  };
  // clear image 3
  // Clear the image
  const clearimage3 = () => {
    setimage3(null);
    setFile3(null);
    hiddenFileInput3.current.value = null;
  };

  const [currentCloudinaryId4, setCurrentCloudinaryId4] = useState()
  const [currentImageLink4, setCurrentImageLink4] = useState()
  const [oldClouadinaryId4, setOldClouadinaryId4] = useState()
  //get referance to first image uploader
  const hiddenFileInput4 = React.useRef(null);

  const [file4, setFile4] = useState(null); // For storing the file to be uploaded
  const handleimage4Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile4(file); // Store file for later Cloudinary upload
      const reader = new FileReader();
      reader.onloadend = () => {
        setimage4(reader.result); // Show image preview
      };
      reader.readAsDataURL(file);
    }
  };
  // clear image 4
  // Clear the image
  const clearimage4 = () => {
    setimage4(null);
    setFile4(null);
    hiddenFileInput4.current.value = null;
  };

  const [currentCloudinaryId5, setCurrentCloudinaryId5] = useState()
  const [currentImageLink5, setCurrentImageLink5] = useState()
  const [oldClouadinaryId5, setOldClouadinaryId5] = useState()
  //get referance to first image uploader
  const hiddenFileInput5 = React.useRef(null);

  const [file5, setFile5] = useState(null); // For storing the file to be uploaded
  const handleimage5Change = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile5(file); // Store file for later Cloudinary upload
      const reader = new FileReader();
      reader.onloadend = () => {
        setimage5(reader.result); // Show image preview
      };
      reader.readAsDataURL(file);
    }
  };
  // clear image 5
  // Clear the image
  const clearimage5 = () => {
    setimage5(null);
    setFile5(null);
    hiddenFileInput5.current.value = null;
  };

  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();


  // Get the saloon general infos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/album", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        setCurrentCloudinaryId1(res.data[0].cloudinary_image1_id)
        setCurrentImageLink1(res.data[0].image1);
        setCurrentCloudinaryId2(res.data[0].cloudinary_image2_id)
        setCurrentImageLink2(res.data[0].image2);
        setCurrentCloudinaryId3(res.data[0].cloudinary_image3_id)
        setCurrentImageLink3(res.data[0].image3);
        setCurrentCloudinaryId4(res.data[0].cloudinary_image4_id)
        setCurrentImageLink4(res.data[0].image4);
        setCurrentCloudinaryId5(res.data[0].cloudinary_image5_id)
        setCurrentImageLink5(res.data[0].image5);
        setimage1(res.data[0].image1);
        setimage2(res.data[0].image2);
        setimage3(res.data[0].image3);
        setimage4(res.data[0].image4);
        setimage5(res.data[0].image5);
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // Update Saloon Infos
  const handleUpdateAlbum = async (e) => {
    e.preventDefault();
    const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dxg9wvfwv/image/upload';
    const uploadPreset = 'ml_default';
    try {
      let res = 0;
      const files = [file1, file2, file3, file4, file5];
      const existingData = [
        { cloudinaryId: currentCloudinaryId1, path: currentImageLink1 },
        { cloudinaryId: currentCloudinaryId2, path: currentImageLink2 },
        { cloudinaryId: currentCloudinaryId3, path: currentImageLink3 },
        { cloudinaryId: currentCloudinaryId4, path: currentImageLink4 },
        { cloudinaryId: currentCloudinaryId5, path: currentImageLink5 }
      ];
      const imageData = await Promise.all(
        files.map(async (file, index) => {
          if (file) {
            const image = new FormData();
            image.append('file', file);
            image.append('upload_preset', uploadPreset);
            image.append('folder', "albums");

            const response = await fetch(cloudinaryUrl, {
              method: "post",
              mode: "cors",
              body: image
            });

            if (!response.ok) {
              throw new Error('Failed to upload image');
            }

            const json = await response.json();
            return {
              cloudinaryId: json.public_id,
              path: json.secure_url
            };
          } else {
            // Use existing data if file is null
            return existingData[index];
          }
        })
      );

      const saloonData = {
        image1link: imageData[0].path,
        image1id: imageData[0].cloudinaryId,
        oldImage1id: oldClouadinaryId1,
        image2link: imageData[1].path,
        image2id: imageData[1].cloudinaryId,
        oldImage2id: oldClouadinaryId2,
        image3link: imageData[2].path,
        image3id: imageData[2].cloudinaryId,
        oldImage3id: oldClouadinaryId3,
        image4link: imageData[3].path,
        image4id: imageData[3].cloudinaryId,
        oldImage4id: oldClouadinaryId4,
        image5link: imageData[4].path,
        image5id: imageData[4].cloudinaryId,
        oldImage5id: oldClouadinaryId5,
      }
 
      res = await axios.put(`/album`, saloonData, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });

      if (res.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Virksomheds album opdateret",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="Album">
        <div className="Albumheader">
          <InfoOutlineIcon /> Upload maksimalt 5 billeder - 5 MB maksimal
          størrelse
        </div>
        <div className="Galleriecontainer">
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  image1
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput1}
                  onChange={handleimage1Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput1.current.click()
                    setOldClouadinaryId1(currentCloudinaryId1)
                  }}

                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => { clearimage1(); setOldClouadinaryId1(currentCloudinaryId1); setCurrentCloudinaryId1(null); setCurrentImageLink1(null) }}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  image2
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput2}
                  onChange={handleimage2Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput2.current.click()
                    setOldClouadinaryId2(currentCloudinaryId2)
                  }}

                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => { clearimage2(); setOldClouadinaryId2(currentCloudinaryId2); setCurrentCloudinaryId2(null); setCurrentImageLink2(null) }}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  image3
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput3}
                  onChange={handleimage3Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput3.current.click()
                    setOldClouadinaryId3(currentCloudinaryId3)
                  }}

                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => { clearimage3(); setOldClouadinaryId3(currentCloudinaryId3); setCurrentCloudinaryId3(null); setCurrentImageLink3(null) }}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  image4
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput4}
                  onChange={handleimage4Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput4.current.click()
                    setOldClouadinaryId4(currentCloudinaryId4)
                  }}

                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => { clearimage4(); setOldClouadinaryId4(currentCloudinaryId1); setCurrentCloudinaryId4(null); setCurrentImageLink4(null) }}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  image5
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput5}
                  onChange={handleimage5Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput5.current.click()
                    setOldClouadinaryId5(currentCloudinaryId5)
                  }}

                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => { clearimage5(); setOldClouadinaryId5(currentCloudinaryId5); setCurrentCloudinaryId5(null); setCurrentImageLink5(null) }}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Button className="Updatebtn" onClick={handleUpdateAlbum}>
          Opdatering
        </Button>
      </div>
    </>
  );
}

export default Album;
