import React, { useContext, useEffect, useState } from "react";
import "./Fergotpass.css";
import Loginright from "./Loginright";
import { Input, Button, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { Authcontext } from "./context/Authcontext";
import axios from "axios";

function Fergotpass() {
  // call loginsaloon function from state and return response
  // get current user to check if saloon is already connected or not
  const { currentuser } = useContext(Authcontext);

  // handle animation for loading requests
  const [isLoading, setisLoading] = useState(false);

  // handle naviguation between pages
  const navigate = useNavigate();

  // declare toast for notifications
  const toast = useToast();

  // store data from inputs
  const [inputs, setInputs] = useState({
    email: "",
  });

  // handle getting the data from the inputs
  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle submitting the form
  const handlesubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      if (inputs.email === "") {
        toast({
          title: "Skriv fejl",
          description: "E-mail-feltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "warning",
        });
        setisLoading(false);
      } else {
        const res = await axios.post("/saloon/verify", { email: inputs.email });

        if (res.status === 200) {
          localStorage.setItem("forgotpasssaloon", res.data.id_saloon);
          navigate("/login/fergotpassword/otp", {
            state: { pin: res.data.pin_number },
          });
          setisLoading(false);
        }
      }
    } catch (err) {
      if (err.status === 404) {
        toast({
          title: "Bekræftelsesfejl",
          description: err.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  // redirect if there is a current user to dashboard
  useEffect(() => {
    if (currentuser) {
      navigate("/b2b/dashboard");
    }
  }, [currentuser, navigate]);

  return (
    <>
      <div className="Fergotpass">
        <div className="Fergotpasscontainer">
          <Link to="/">
            <img
              src="../assets/Logo/logo.png"
              alt=""
              className="Logocontainer"
            ></img>
          </Link>
          <div className="Header">Har du glemt dit kodeord ?</div>
          <div className="Subheader">
            Indtast venligst den adresse, der er knyttet til din konto.
          </div>
          <div className="Label">E-mail</div>
          <Input
            name="email"
            required
            variant="filled"
            placeholder="din e-mail@gmail.dk"
            size="lg"
            className="Emailinput"
            type="email"
            onChange={handlechange}
          />
          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handlesubmit}>
                send
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                send
              </Button>
            </>
          )}
        </div>
        <Loginright className="Loginright" />
      </div>
    </>
  );
}

export default Fergotpass;
