import {
  FaCheck,
  FaLongArrowAltLeft,
  FaChevronDown,
  FaPlus,
  FaMinus,
  FaTimes,
} from "react-icons/fa";
import {
  Divider,
  Grid, InputGroup,
  Progress,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {useEffect, useState, useMemo, useRef} from "react";
import {Calendar, momentLocalizer} from "react-big-calendar";
import {doc, onSnapshot} from "firebase/firestore";
import {CiSearch} from "react-icons/ci";
import Calendars from "react-calendar";
import {db} from "../firebaseConfig";
import {useCallback} from "react";
import Cookies from "js-cookie";
import moment from "moment";
import axios from "axios";
import {
  useDisclosure,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  MenuButton,
  ModalBody,
  MenuItem,
  MenuList,
  useToast,
  Button,
  Input,
  Badge,
  Image,
  Modal,
  Text,
  Flex,
  Menu,
  Box,
} from "@chakra-ui/react";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";

import "moment/locale/da";

const event_style_getter = (event) => {
  let backgroundColor;

  if (event.appointment_status === "accepted") {
    backgroundColor = "#2f7185";
  } else if (event.appointment_status === "declined") {
    backgroundColor = "#e45757";
  } else if (event.appointment_status === "completed") {
    backgroundColor = "#22A994";
  } else {
    backgroundColor = "#e0e0e0"; // Default color
  }

  return {
    style: {
      backgroundColor,
    },
  };
};

const PopUpHeader = ({currentPopupStatus, setCurrentPopupStatus}) => {
  switch (currentPopupStatus) {
    case "service":
      return (
        <Box
          onClick={() => setCurrentPopupStatus("main")}
          cursor={"pointer"}
          padding={"4px"}
          paddingLeft={"15px"}
          paddingRight={"15px"}
          border={"solid 3px #e5dedb"}
          width={"fit-content"}
          borderRadius={"40%"}
        >
          <FaLongArrowAltLeft style={{fontSize: "36px", color: "#494949"}}/>{" "}
        </Box>
      );
    case "subService":
      return (
        <Box
          onClick={() => setCurrentPopupStatus("service")}
          cursor={"pointer"}
          padding={"4px"}
          paddingLeft={"15px"}
          paddingRight={"15px"}
          border={"solid 3px #e5dedb"}
          width={"fit-content"}
          borderRadius={"40%"}
        >
          <FaLongArrowAltLeft style={{fontSize: "36px", color: "#494949"}}/>{" "}
        </Box>
      );
    case "tags":
      return (
        <Box
          onClick={() => setCurrentPopupStatus("main")}
          cursor={"pointer"}
          padding={"4px"}
          paddingLeft={"15px"}
          paddingRight={"15px"}
          border={"solid 3px #e5dedb"}
          width={"fit-content"}
          borderRadius={"40%"}
        >
          <FaLongArrowAltLeft style={{fontSize: "36px", color: "#494949"}}/>{" "}
        </Box>
      );
    case "tagProducts":
      return (
        <Box
          onClick={() => setCurrentPopupStatus("tags")}
          cursor={"pointer"}
          padding={"4px"}
          paddingLeft={"15px"}
          paddingRight={"15px"}
          border={"solid 3px #e5dedb"}
          width={"fit-content"}
          borderRadius={"40%"}
        >
          <FaLongArrowAltLeft style={{fontSize: "36px", color: "#494949"}}/>{" "}
        </Box>
      );
    case "calendar":
      return (
        <Box
          onClick={() => setCurrentPopupStatus("main")}
          cursor={"pointer"}
          padding={"4px"}
          paddingLeft={"15px"}
          paddingRight={"15px"}
          border={"solid 3px #e5dedb"}
          width={"fit-content"}
          borderRadius={"40%"}
        >
          <FaLongArrowAltLeft style={{fontSize: "36px", color: "#494949"}}/>{" "}
        </Box>
      );

    default:
      return "Aftale Information";
  }
};

const AppointmentInformation = ({
                                  selected_event,
                                  getServices,
                                  getTags,
                                  setCurrentPopupStatus,
                                  declineAppointment,
                                }) => {
  return (
    <Flex direction={"column"} width={"100%"} padding={"10px"}>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Aftale status :
        </Text>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"340px"}
        >
          <Badge
            background={
              selected_event.appointment_status === "accepted"
                ? "#2f7185"
                : selected_event.appointment_status === "declined"
                  ? "#C53030"
                  : selected_event.appointment_status === "completed"
                    ? "#22A994"
                    : "#e0e0e0"
            }
            color={"white"}
          >
            {selected_event.appointment_status === "accepted" && "Accepteret"}
            {selected_event.appointment_status === "declined" && "Afvist"}
            {selected_event.appointment_status === "completed" && "Fuldført"}
          </Badge>
          <Menu>
            <MenuButton
              as={Button}
              fontWeight="normal"
              background="#edeaef"
              border="3px solid #cdbeb9"
              borderRadius="25px"
              width="65px"
              height="35px"
              textAlign="center"
              disabled={
                selected_event.appointment_status === "declined" ||
                selected_event.appointment_status === "completed"
              }
            >
              <FaChevronDown style={{width: "fit-content", margin: "auto"}}/>
            </MenuButton>
            <MenuList>
              <MenuItem isDisabled>Accepteret</MenuItem>
              <MenuItem
                onClick={() => declineAppointment(selected_event.id)}
                isDisabled={
                  selected_event.appointment_status === "declined" ||
                  selected_event.appointment_status === "completed"
                }
              >
                Afvist
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Kundenavn :
        </Text>
        <Box w={"340px"}>
          <Text>{selected_event.client_name}</Text>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Service :
        </Text>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={4}
        >
          <Box w={"260px"}>
            <Text>{selected_event.service}</Text>
          </Box>
          <Button
            isDisabled={
              selected_event &&
              (selected_event.appointment_status === "declined" ||
                selected_event.appointment_status === "completed")
            }
            onClick={() => {
              getServices();
              setCurrentPopupStatus("service");
            }}
            fontWeight={"normal"}
            background={"#edeaef"}
            border={"3px solid #cdbeb9"}
            borderRadius={"25px"}
            width={"65px"}
            height={"35px"}
            textAlign={"center"}
          >
            Tilføj
          </Button>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Produkt :
        </Text>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={4}
        >
          <Box w={"260px"}>
            <Text>{selected_event.product}</Text>
          </Box>
          <Button
            isDisabled={
              selected_event &&
              (selected_event.appointment_status === "declined" ||
                selected_event.appointment_status === "completed")
            }
            onClick={() => {
              getTags();
              setCurrentPopupStatus("tags");
            }}
            fontWeight={"normal"}
            background={"#edeaef"}
            border={"3px solid #cdbeb9"}
            borderRadius={"25px"}
            width={"65px"}
            height={"35px"}
            textAlign={"center"}
          >
            Tilføj
          </Button>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Dato :
        </Text>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"340px"}
        >
          <Text>
            {moment(selected_event.start).format("dddd, MMMM D, YYYY")}
          </Text>
          <Button
            isDisabled={
              selected_event &&
              (selected_event.appointment_status === "declined" ||
                selected_event.appointment_status === "completed")
            }
            onClick={() => setCurrentPopupStatus("calendar")}
            fontWeight={"normal"}
            background={"#edeaef"}
            border={"3px solid #cdbeb9"}
            borderRadius={"25px"}
            width={"65px"}
            height={"35px"}
            textAlign={"center"}
          >
            Ændr
          </Button>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Tidspunkt :
        </Text>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          w={"340px"}
        >
          <Text>
            <span style={{fontWeight: "bold", marginRight: "8px"}}>
              Fra:{" "}
            </span>
            {moment(selected_event.start).format("HH:mm")}
            <span
              style={{
                fontWeight: "bold",
                marginRight: "8px",
                marginLeft: "8px",
              }}
            >
              Til:
            </span>
            {moment(selected_event.end).format("HH:mm")}
          </Text>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Pris :
        </Text>
        <Box w={"340px"}>
          <Text>{selected_event.service_price} Kr</Text>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Medarbejder :
        </Text>
        <Box w={"340px"}>
          <Text>{selected_event.staff_member}</Text>
        </Box>
      </Flex>
      <Flex
        direction={"row"}
        width={"100%"}
        py={2}
        borderBottom={"2px solid #e5ded8"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Text fontWeight={"bold"} fontSize={"lg"}>
          Medlemskab :
        </Text>
        <Box w={"340px"}>
          <Text fontWeight={"regular"} letterSpacing={2}>
            BASIS
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

const ServicesInformation = ({
                               services,
                               setSelectedServiceIndex,
                               setCurrentPopupStatus,
                             }) => {
  return (
    <div
      className="select_event_modal_container"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", // Two equal-width columns
        gap: "16px", // Adds space between grid items
        gridAutoRows: "min-content", // Makes each row height fit its content
        alignContent: "start", // Aligns the items at the top of the grid
        overflowY: "auto", // Scroll if there are more than 6 elements
        paddingBottom: "16px", // Optional: Adds padding at the bottom
      }}
    >
      {services &&
        services.map((service, index) => {
          return (
            <Button
              onClick={() => {
                setSelectedServiceIndex(index);
                setCurrentPopupStatus("subService");
              }}
              background={"none"}
              border={"4px solid #edeadf"}
              borderRadius={"30%"}
              key={service.id}
              height={"150px"}
              width={"170px"}
              margin={"auto"}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                fontSize="5xl" // Larger size for the "logo"
                fontWeight="bold"
                mb={2} // Margin bottom to create space between logo and name
                color={"#e5ded8"}
              >
                {service.name.slice(0, 2).toUpperCase()}{" "}
                {/* First two letters as logo */}
              </Box>
              <Text fontSize="md">
                {" "}
                {/* Display the full name */}
                {service.name}
              </Text>
            </Button>
          );
        })}
    </div>
  );
};

const SubServicesInformation = ({
                                  services,
                                  selectedServiceIndex,
                                  selected_event,
                                  set_selected_event,
                                }) => {
  return (
    <Box>
      {services && (
        <Box
          background={"none"}
          border={"4px solid #edeadf"}
          borderRadius={"30%"}
          key={services[selectedServiceIndex].id}
          height={"150px"}
          width={"170px"}
          margin={"auto"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            fontSize="5xl"
            fontWeight="bold"
            mb={2}
            color={"#e5ded8"}
          >
            {services[selectedServiceIndex].name.slice(0, 2).toUpperCase()}{" "}
          </Box>
          <Text fontSize="md" fontWeight={"bold"}>
            {" "}
            {services[selectedServiceIndex].name}
          </Text>
        </Box>
      )}
      <Box
        border={"3px solid #cdbeb9"}
        height={"fit-content"}
        width={"80%"}
        py={"12px"}
        mx={"auto"}
        my={"2"}
      >
        {services[selectedServiceIndex].subservices.map((subService, index) => {
          return (
            <>
              <Flex
                position={"relative"}
                height={"40px"}
                margin={"auto"}
                width={"90%"}
                marginTop={"12px"}
              >
                <Box>
                  <Box>{subService.name}</Box>
                  <Box fontWeight={"bold"}>Kr. {subService.price}</Box>
                </Box>
                {!selected_event.selectedServices.includes(subService.id) ? (
                  <Button
                    position="absolute"
                    right="10px"
                    top="-2px"
                    background="#edeaef"
                    border="3px solid #cdbeb9"
                    borderRadius="25px"
                    width="60px"
                    textAlign="center"
                    onClick={() => {
                      set_selected_event((selected_event) => ({
                        ...selected_event,
                        selectedServices: [
                          ...selected_event.selectedServices,
                          subService.id,
                        ],
                        // service: selected_event.service ? `${selected_event.service}, ${subService.name}` : subService.name,
                      }));
                    }}
                  >
                    Tilføj
                  </Button>
                ) : (
                  <Button
                    position="absolute"
                    right="10px"
                    top="-2px"
                    background="#edeaef"
                    border="3px solid #cdbeb9"
                    borderRadius="25px"
                    width="60px"
                    textAlign="center"
                    onClick={() => {
                      set_selected_event((selected_event) => ({
                        ...selected_event,
                        selectedServices:
                          selected_event.selectedServices.filter(
                            (id) => id !== subService.id
                          ),
                      }));
                    }}
                  >
                    <FaCheck/>
                  </Button>
                )}
              </Flex>
              {index <
                services[selectedServiceIndex].subservices.length - 1 && (
                  <Box
                    width={"85%"}
                    margin={"auto"}
                    marginTop={"12px"}
                    borderBottom={"2px solid #e5ded8"}
                  ></Box>
                )}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

const TagsInformation = ({
                           tags,
                           setSelectedTagIndex,
                           setCurrentPopupStatus,
                           getProductsByTags,
                         }) => {
  return (
    <div
      className="select_event_modal_container"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", // Two equal-width columns
        gap: "16px", // Adds space between grid items
        gridAutoRows: "min-content", // Makes each row height fit its content
        alignContent: "start", // Aligns the items at the top of the grid
        overflowY: "auto", // Scroll if there are more than 6 elements
        paddingBottom: "16px", // Optional: Adds padding at the bottom
      }}
    >
      {tags &&
        tags.map((tag, index) => {
          return (
            <Button
              onClick={() => {
                setSelectedTagIndex(index);
                setCurrentPopupStatus("tagProducts");
                getProductsByTags(tag.id);
              }}
              background={"none"}
              border={"4px solid #edeadf"}
              borderRadius={"30%"}
              key={tag.id}
              height={"150px"}
              width={"170px"}
              margin={"auto"}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                fontSize="5xl" // Larger size for the "logo"
                fontWeight="bold"
                mb={2} // Margin bottom to create space between logo and name
                color={"#e5ded8"}
              >
                {tag.name.slice(0, 2).toUpperCase()}{" "}
                {/* First two letters as logo */}
              </Box>
              <Text fontSize="md">
                {" "}
                {/* Display the full name */}
                {tag.name}
              </Text>
            </Button>
          );
        })}
    </div>
  );
};

const ProductsInformation = ({
                               products,
                               tags,
                               selectedTagIndex,
                               currentBottles,
                               setCurrentBottles,
                               selected_event,
                               set_selected_event,
                             }) => {
  const [filterText, setFilterText] = useState("");

  // Initialize currentBottles if it’s empty or has an incorrect length
  useEffect(() => {
    if (!currentBottles || currentBottles.length !== products.length) {
      setCurrentBottles(Array(products.length).fill(0));
    }
  }, [products, currentBottles, setCurrentBottles]);

  // Filter products based on the input value
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <Box
      width={"100%"}
      height={"auto"}
    >
      {products && (
        <Flex
          justifyContent={"space-between"}
        >
          <Box>
            <Text fontWeight={"bold"} fontSize={"14px"}>
              Søg efter produkt
            </Text>
            <InputGroup
              flex="1"
              startElement={<CiSearch size={20} style={{marginRight: '8px'}}/>}
            >
              <Input
                borderWidth="3px"
                borderStyle="solid"
                borderColor="#cdbeb9"
                borderRadius="25px"
                paddingLeft={"30px"}
                fontSize="12px"
                height="35px"
                _hover={{borderColor: "#cdbeb9"}}
                _focus={{borderColor: "#cdbeb9", boxShadow: "none"}}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder={"Søg efter produkt"}
              />
            </InputGroup>
          </Box>
          <Box
            background={"none"}
            border={"4px solid #edeadf"}
            borderRadius={"30%"}
            key={tags[selectedTagIndex].id}
            height={"120px"}
            width={"130px"}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box fontSize="5xl" fontWeight="bold" mb={2} color={"#e5ded8"}>
              {tags[selectedTagIndex].name.slice(0, 2).toUpperCase()}
            </Box>
            <Text fontSize="md" fontWeight={"bold"}>
              {tags[selectedTagIndex].name}
            </Text>
          </Box>
        </Flex>
      )}
      <Box
        border={"3px solid #cdbeb9"}
        width={"100%"}
        height={"350px"}
        overflowY={"scroll"}
        mt={"4"}
        px={"4"}
      >
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <Box
              key={product.productId}
              borderBottom={"2px solid #e5ded8"}
              py={"4"}
            >
              <Flex
                justifyContent={"space-between"}
                alignItems={"start"}
              >
                <Flex
                  gap={"1"}
                  flexDirection={"column"}
                >
                  <Text fontWeight={"bold"}>{product.name}</Text>
                  <Flex gap={"8px"}>
                    {product.skus.map((sku, index2) => {
                      const existingProduct = selected_event.selectedProducts.find(
                        (item) => item.productId === product.productId
                      );

                      return (
                        <Button
                          key={index2}
                          onClick={() => {
                            const updatedBottles = [...currentBottles];
                            updatedBottles[index] = index2;
                            setCurrentBottles(updatedBottles);

                            if (existingProduct) {
                              set_selected_event((selected_event) => ({
                                ...selected_event,
                                selectedProducts: selected_event.selectedProducts.map(
                                  (item) =>
                                    item.productId === product.productId
                                      ? {...item, skuId: sku.skuId}
                                      : item
                                ),
                              }));
                            } else {
                              set_selected_event((selected_event) => ({
                                ...selected_event,
                                selectedProducts: [
                                  ...selected_event.selectedProducts,
                                  {
                                    productId: product.productId,
                                    quantity: 1,
                                    skuId: sku.skuId,
                                  },
                                ],
                              }));
                            }
                          }}
                          background={
                            currentBottles[index] === index2 ||
                            (existingProduct && existingProduct.skuId === sku.skuId)
                              ? "#edeadf"
                              : "none"
                          }
                          marginRight={"8px"}
                          height={"fit-content"}
                          border={"2px solid #eddedb "}
                          fontWeight={"bold"}
                          padding={"12px"}
                          paddingTop={"2px"}
                          paddingBottom={"2px"}
                          width={"fit-content"}
                          borderRadius={"25px"}
                        >
                          {sku.size}ml
                        </Button>
                      );
                    })}
                  </Flex>
                  {currentBottles[index] !== undefined && (
                    <Text>Kr. {product.skus[currentBottles[index]].price},-</Text>
                  )}
                </Flex>
                {!selected_event.selectedProducts.some(
                  (item) => item.productId === product.productId
                ) ? (
                  <Button
                    background={"#edeaef"}
                    border={"3px solid #cdbeb9"}
                    borderRadius={"25px"}
                    width={"60px"}
                    textAlign={"center"}
                    onClick={() => {
                      set_selected_event((selected_event) => ({
                        ...selected_event,
                        selectedProducts: [
                          ...selected_event.selectedProducts,
                          {
                            productId: product.productId,
                            quantity: 1,
                            skuId: product.skus[currentBottles[index]].skuId,
                          },
                        ],
                      }));
                    }}
                  >
                    Tilføj
                  </Button>
                ) : (
                  <Button
                    background={"#edeaef"}
                    border={"3px solid #cdbeb9"}
                    borderRadius={"25px"}
                    width={"60px"}
                    textAlign={"center"}
                    onClick={() => {
                      set_selected_event((selected_event) => ({
                        ...selected_event,
                        selectedProducts: selected_event.selectedProducts.filter(
                          (item) => item.productId !== product.productId
                        ),
                      }));
                    }}
                  >
                    <FaCheck/>
                  </Button>
                )}
              </Flex>
            </Box>
          ))
        ) : (
          <Box
            width={"90%"}
            margin={"auto"}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            No products found
          </Box>
        )}
      </Box>
    </Box>
  );
};

const DateAndTimePicker = ({
                             selectedServices,
                             onDateTimeSelected,
                             closedDays,
                           }) => {
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const toast = useToast();

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setSelectedTime(null);

    // Fetch available time slots for the selected date
    try {
      const formattedDate = date.toISOString().split("T")[0];
      const res = await axios.post(
        "/appointment/slots",
        {
          treatmentIds: selectedServices,
          date: formattedDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        }
      );

      if (res.status === 200 && res.data.success) {
        // Extract time slots from response
        const slots = res.data.slots.map(
          (slot) => `${slot.start} - ${slot.end}`
        );
        setAvailableTimeSlots(slots);
      }
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning.";
        } else if (status === 401) {
          message = "Uautoriseret adgang.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Fejl",
          description: "Der opstod en fejl under hentning af tidsluker.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleTimeSlotClick = (time) => {
    setSelectedTime(time);
    onDateTimeSelected(selectedDate, time);
  };

  // Convert closed days to numerical values (0 = Sunday, 6 = Saturday)
  const closedDaysNumeric = useMemo(() => {
    const daysMap = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };
    return closedDays.map((day) => daysMap[day.toLowerCase()]);
  }, [closedDays]);

  // Disable calendar tiles for closed days
  const isDayClosed = (date) => {
    return closedDaysNumeric.includes(date.getDay());
  };

  const isPastDate = (date) => {
    const today = new Date();
    // Set the hours to 0 to compare only the date part
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date < today;
  };

  return (
    <Box>
      <Box
        width={"100%"}
        height={"fit-content"}
        maxWidth={"800px"}
        margin={"0 auto"}
        borderRadius={"25px"}
      >
        <Calendars
          onChange={handleDateChange}
          value={selectedDate}
          className="custom-calendar"
          tileDisabled={({date}) => isDayClosed(date) || isPastDate(date)}
        />
      </Box>

      {selectedDate && (
        <Box
          position={"relative"}
          height={"200px"}
          overflowY={"scroll"}
          mt={"4"}
        >
          <Text fontWeight="bold" mb={3}>
            Vælg et tidspunkt:
          </Text>
          <SimpleGrid columns={3} spacing={4}>
            {availableTimeSlots.map((slot, index) => (
              <Button
                key={index}
                onClick={() => handleTimeSlotClick(slot)}
                variant={selectedTime === slot ? "solid" : "outline"}
                colorScheme={selectedTime === slot ? "green" : "gray"}
              >
                {slot}
              </Button>
            ))}
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

const PaymentStatusModal = ({isOpen, onClose, status, message}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isOpen && (status === "success" || status === "error")) {
      setProgress(0);
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          } else {
            return prev + 5;
          }
        });
      }, 100);
      return () => clearInterval(interval);
    }
  }, [isOpen, status]);

  useEffect(() => {
    if (isOpen && (status === "success" || status === "error")) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, status, onClose]);

  const bgColor = useColorModeValue(
    status === "success"
      ? "green.50"
      : status === "error"
        ? "red.50"
        : "gray.50",
    status === "success"
      ? "green.700"
      : status === "error"
        ? "red.700"
        : "gray.700"
  );

  const borderColor = useColorModeValue(
    status === "success"
      ? "green.500"
      : status === "error"
        ? "red.500"
        : "blue.500",
    status === "success"
      ? "green.300"
      : status === "error"
        ? "red.300"
        : "blue.300"
  );

  const icon =
    status === "success" ? (
      <FaCheck size={50} color="green.500"/>
    ) : status === "error" ? (
      <FaTimes size={50} color="red.500"/>
    ) : (
      <Spinner size="xl" color="blue.500"/>
    );

  const headerText =
    status === "success"
      ? "Betaling Vellykket"
      : status === "error"
        ? "Betaling Fejlede"
        : "Betaling Igang";

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="md" isCentered>
      <ModalOverlay/>
      <ModalContent
        borderRadius="lg"
        border={`2px solid ${borderColor}`}
        padding="20px"
        bg={bgColor}
        maxW="400px"
      >
        {(status === "success" || status === "error") && (
          <Progress
            size="xs"
            value={progress}
            borderRadius="lg"
            mb={4}
            isAnimated={true}
            hasStripe
          />
        )}
        <ModalHeader textAlign="center" fontSize="lg" fontWeight="bold">
          {headerText}
        </ModalHeader>
        <ModalBody textAlign="center">
          <Box
            mb={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="60px"
          >
            {icon}
          </Box>
          <Text>{message}</Text>
        </ModalBody>
        {/* Optionally, you can remove the ModalFooter if you don't want the 'Luk' button */}
      </ModalContent>
    </Modal>
  );
};

function CalendarPage() {
  const salonId = localStorage.getItem("firestoreIdentifier");
  const [selected_event, set_selected_event] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [pantQuantity, setPantQuantity] = useState(0);
  const [closedDays, setClosedDays] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const momentLocal = momentLocalizer(moment);
  const transactionListener = useRef(null);
  const toast = useToast();

  const {
    isOpen: isPaymentStatusOpen,
    onOpen: onPaymentStatusOpen,
    onClose: onPaymentStatusCloseModal,
  } = useDisclosure();

  const onPaymentStatusClose = () => {
    onPaymentStatusCloseModal();
    onPaymentClose();
    onSecondClose();
    onFirstClose();
  };

  const makeCashPayment = async () => {
    if (!selected_event) {
      toast({
        title: "Fejl",
        description: "Ingen aftale valgt.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const appointmentId = selected_event.id;
    const paymentMethod = "cash";
    const paymentAmount = checkoutData
      ? checkoutData.checkoutTotalPrice - pantQuantity * 20
      : 0;
    const discount = pantQuantity * 20;
    const staffId = selected_event.staff_id || 0;

    const requestBody = {
      paymentMethod,
      paymentAmount,
      discount,
      staffId,
    };

    try {
      const res = await axios.post(
        `/appointment/${appointmentId}/payments/cash`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        }
      );

      if (res.status === 204) {
        setPaymentStatus("success");
        setPaymentMessage("Kontant betaling gennemført.");
        onPaymentStatusOpen();
        setRefresh(!refresh);
      }
    } catch (err) {
      let message = "Der opstod en fejl.";
      if (err.response) {
        const {status} = err.response;
        if (status === 400) {
          message = "Ugyldig anmodning.";
        } else if (status === 401) {
          message = "Uautoriseret adgang.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        }
      }
      setPaymentStatus("error");
      setPaymentMessage(message);
      onPaymentStatusOpen();
    }
  };

  const makeFlatpayPayment = async () => {
    if (!selected_event) {
      toast({
        title: "Fejl",
        description: "Ingen aftale valgt.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const appointmentId = selected_event.id;
    const discount = pantQuantity * 20 * 100;
    const staffId = selected_event.staffId || 0;

    const requestBody = {
      discount,
      staffId,
    };

    try {
      const res = await axios.post(
        `/appointment/${appointmentId}/payments/flatpay`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        }
      );

      if (res.status === 200 && res.data.success) {
        const {paymentReference} = res.data;

        // Store transaction in Firestore
        const transactionRef = doc(db, "transactions", paymentReference);

        // Cleanup previous listener if any
        if (transactionListener.current) {
          transactionListener.current();
        }

        // Listen for status updates
        onSnapshot(transactionRef, (docSnap) => {
          if (docSnap.exists()) {
            const transactionData = docSnap.data();
            if (transactionData.status === "approved") {
              setPaymentStatus("success");
              setPaymentMessage("Flatpay betaling godkendt.");
              onPaymentStatusOpen();
              setRefresh(!refresh);
              transactionListener.current();
              transactionListener.current = null;
            } else if (transactionData.status === "declined") {
              setPaymentStatus("error");
              setPaymentMessage("Flatpay betaling afvist.");
              onPaymentStatusOpen();
              transactionListener.current();
              transactionListener.current = null;
            }
          }
        });

        // Optionally, provide immediate feedback
        setPaymentStatus("pending");
        setPaymentMessage("Flatpay betaling igangsat. Afventer godkendelse...");
        onPaymentStatusOpen();
      }
    } catch (err) {
      let message = "Der opstod en fejl.";
      if (err.response) {
        const {status} = err.response;
        if (status === 400) {
          message = "Ugyldig anmodning.";
        } else if (status === 401) {
          message = "Uautoriseret adgang.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        }
      }
      setPaymentStatus("error");
      setPaymentMessage(message);
      onPaymentStatusOpen();
    }
  };

  // Listen for salon document updates
  useEffect(() => {
    if (salonId) {
      const salonDocRef = doc(db, "salons", salonId);

      const unsubscribe = onSnapshot(salonDocRef, (doc) => {
        if (doc.exists()) {
          console.log("Salon document updated:", doc.data());
          setRefresh((prev) => !prev); // Toggle refresh state
        }
      });

      // Cleanup listener on component unmount
      return () => unsubscribe();
    }
  }, [salonId]);

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (transactionListener.current) {
        transactionListener.current();
      }
    };
  }, []);

  // Load all appointments when page loads
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await axios.get("/appointment", {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        });

        if (res.status === 200) {
          let updateEventsList = [];
          setEventsList([]);

          for (let index = 0; index < res.data.length; index++) {
            const newEvent = {
              id: res.data[index].appointmentId,
              client_name: res.data[index].clientName,
              selectedServices: res.data[index].subservices.map(
                (subservice) => subservice.id
              ),
              selectedProducts: res.data[index].products.map((product) => ({
                productId: product.id,
                quantity: product.quantity,
                skuId: product.skuId,
              })),
              service: res.data[index].subservices
                .map((subservice) => subservice.name)
                .join(", "),
              product: res.data[index].products
                .map((product) => product.name + "-" + product.size + "ml")
                .join(", "),
              service_price:
                res.data[index].subservices.reduce(
                  (total, subservice) => total + Number(subservice.price),
                  0
                ) +
                res.data[index].products.reduce(
                  (total, product) =>
                    total +
                    Number(product.price) * Number(product.totalQuantity),
                  0
                ),
              staff_member: res.data[index].staffName,
              staff_id: res.data[index].staffId,
              appointment_status: res.data[index].appointmentStatus,
              appointment_note: res.data[index].comment,
              start: moment(
                res.data[index].appointmentDate.split("T")[0] +
                "T" +
                res.data[index].appointmentStart
              ).toDate(),
              end: moment(
                res.data[index].appointmentDate.split("T")[0] +
                "T" +
                res.data[index].appointmentEnd
              ).toDate(),
              title:
                res.data[index].clientName +
                " - " +
                res.data[index].subservices
                  .map((subservice) => subservice.name)
                  .join(", "),
              allDay: false,
            };
            if (selected_event && newEvent.id === selected_event.id) {
              // Update the selected event with its latest data
              set_selected_event(newEvent); // Keeps selected_event if not found
            }
            updateEventsList.push(newEvent);
          }
          setEventsList(updateEventsList);
        }
      } catch (err) {
        if (err.response) {
          const {status} = err.response;
          let message = "";
          if (status === 400) {
            message = "Ugyldig anmodning: Mangler token-autorisation.";
          } else if (status === 401) {
            message = "Uautoriseret adgang: Udløbet eller forkert token.";
          } else if (status === 500) {
            message = "Serverfejl. Prøv venligst igen senere.";
          } else {
            message = "Der opstod en fejl.";
          }
          toast({
            title: "Fejl",
            description: message,
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // Fetch closed days
  useEffect(() => {
    const fetchClosedDays = async () => {
      try {
        const res = await axios.get("/appointment/closed", {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        });

        if (res.status === 200) {
          setClosedDays(res.data.closedDays); // Save closed days to state
        }
      } catch (err) {
        if (err.response) {
          const {status} = err.response;
          let message = "";
          if (status === 400) {
            message = "Ugyldig anmodning: Mangler autorisationstoken.";
          } else if (status === 401) {
            message = "Uautoriseret adgang: Udløbet eller forkert token.";
          } else if (status === 500) {
            message = "Serverfejl. Prøv venligst igen senere.";
          } else {
            message = "Der opstod en fejl.";
          }
          toast({
            title: "Fejl",
            description: message,
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };

    fetchClosedDays();
  }, []);

  // Handle open & close select event popup
  const {
    isOpen: isFirstOpen,
    onOpen: onFirstOpen,
    onClose: onFirstClose,
  } = useDisclosure();
  const {
    isOpen: isSecondOpen,
    onOpen: onSecondOpen,
    onClose: onSecondClose,
  } = useDisclosure();

  // Handle selecting an event
  const handle_select_event = useCallback(
    (event) => {
      set_selected_event(event);
      onFirstOpen();
    },
    [onFirstOpen]
  );

  const [currentPopupStatus, setCurrentPopupStatus] = useState("main");
  const [services, setServices] = useState([]);
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(-1);
  const [tags, setTags] = useState([]);
  const [selectedTagIndex, setSelectedTagIndex] = useState(-1);

  const [products, setProducts] = useState([]);
  const [currentBottles, setCurrentBottles] = useState(
    Array(products.length).fill(null)
  );

  const handleDateTimeSelected = (date, timeSlot) => {
    // Split the timeSlot into start and end times
    const [startTime, endTime] = timeSlot.split(" - ");

    // Parse the date and times
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);

    const newStartDate = new Date(date);
    newStartDate.setHours(startHours, startMinutes, 0, 0);

    const newEndDate = new Date(date);
    newEndDate.setHours(endHours, endMinutes, 0, 0);

    set_selected_event((prevEvent) => ({
      ...prevEvent,
      start: newStartDate,
      end: newEndDate,
    }));
  };

  const updateAppointmentDateTime = async () => {
    if (!selected_event.start || !selected_event.end) {
      toast({
        title: "Error",
        description: "Vælg venligst en dato og et tidspunkt, før du gemmer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const date = selected_event.start.toISOString().split("T")[0]; // Format: 'YYYY-MM-DD'

      const formatTime = (dateObj) => {
        const hours = String(dateObj.getHours()).padStart(2, "0");
        const minutes = String(dateObj.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
      };

      const startTime = formatTime(selected_event.start); // Format: 'HH:MM'
      const endTime = formatTime(selected_event.end); // Format: 'HH:MM'

      const res = await axios.patch(
        `/appointment/${selected_event.id}/date`,
        {
          date,
          startTime,
          endTime,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        }
      );

      if (res.status === 204) {
        toast({
          title: "Succes",
          description: "Aftalens dato og tidspunkt opdateret.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setRefresh(!refresh);
        setCurrentPopupStatus("main");
      }
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning.";
        } else if (status === 401) {
          message = "Uautoriseret adgang.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Fejl",
          description: "Der opstod en fejl under opdatering af aftalen.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const getServices = async () => {
    try {
      const res = await axios.get("/service", {
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("authenticationToken"),
        },
      });
      setServices(res.data);
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning: Mangler token-autorisation.";
        } else if (status === 401) {
          message = "Uautoriseret adgang: Udløbet eller forkert token.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const getTags = async () => {
    try {
      const res = await axios.get("/products/tags", {
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("authenticationToken"),
        },
      });
      setTags(res.data.tags);
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning: Mangler token-autorisation.";
        } else if (status === 401) {
          message = "Uautoriseret adgang: Udløbet eller forkert token.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const getProductsByTags = async (tagId) => {
    try {
      const res = await axios.get(`/products/tags/${tagId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("authenticationToken"),
        },
      });
      await setProducts(res.data.products);
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning: Mangler token-autorisation.";
        } else if (status === 401) {
          message = "Uautoriseret adgang: Udløbet eller forkert token.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const declineAppointment = async (id) => {
    try {
      const res = await axios.put(
        `/appointment/decline/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        }
      );
      selected_event.appointment_status = "declined";
      setRefresh(!refresh);
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning: Mangler token-autorisation.";
        } else if (status === 401) {
          message = "Uautoriseret adgang: Udløbet eller forkert token.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const addNewService = async () => {
    try {
      const data = [...selected_event.selectedServices];
      const res = await axios.patch(
        `/appointment/${selected_event.id}/services`,
        {serviceIds: data},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        }
      );

      if (res.status === 204) {
        setRefresh(!refresh);
        toast({
          title: "Succes",
          description: "Nye services tilføjet.",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      }
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning: Mangler token-autorisation.";
        } else if (status === 401) {
          message = "Uautoriseret adgang: Udløbet eller forkert token.";
        } else if (status === 409) {
          message =
            "Tidskonflikt: Aftaletidspunkt overlapper med eksisterende aftaler.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  const addNewProduct = async () => {
    try {
      const data = [...selected_event.selectedProducts];
      const res = await axios.patch(
        `/appointment/${selected_event.id}/products`,
        {products: data},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: Cookies.get("authenticationToken"),
          },
        }
      );
      if (res.status === 204) {
        setRefresh(!refresh);
        toast({
          title: "Succes",
          description: "Produktlisten opdateret.",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
      }
    } catch (err) {
      if (err.response) {
        const {status} = err.response;
        let message = "";
        if (status === 400) {
          message = "Ugyldig anmodning: Mangler token-autorisation.";
        } else if (status === 401) {
          message = "Uautoriseret adgang: Udløbet eller forkert token.";
        } else if (status === 409) {
          message =
            "Tidskonflikt: Aftaletidspunkt overlapper med eksisterende aftaler.";
        } else if (status === 500) {
          message = "Serverfejl. Prøv venligst igen senere.";
        } else {
          message = "Der opstod en fejl.";
        }
        toast({
          title: "Fejl",
          description: message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // Checkout data holder
  const [checkoutData, setCheckoutData] = useState();

  // Load checkout data
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await axios.get(
          `/appointment/${selected_event.id}/checkout`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: Cookies.get("authenticationToken"),
            },
          }
        );

        if (res.status === 200) {
          setCheckoutData(res.data.checkoutInfos);
        }
      } catch (err) {
        if (err.response) {
          const {status} = err.response;
          let message = "";
          if (status === 400) {
            message = "Ugyldig anmodning: Mangler token-autorisation.";
          } else if (status === 401) {
            message = "Uautoriseret adgang: Udløbet eller forkert token.";
          } else if (status === 500) {
            message = "Serverfejl. Prøv venligst igen senere.";
          } else {
            message = "Der opstod en fejl.";
          }
          toast({
            title: "Fejl",
            description: message,
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };

    // Fetch data only when second modal is open
    if (isSecondOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSecondOpen]);

  // Payment modal handler
  const {
    isOpen: isPaymentOpen,
    onOpen: onPaymentOpen,
    onClose: onPaymentClose,
  } = useDisclosure();

  return (
    <>
      <Box
        className="Calendar"
        bgColor={"#edeadf"}
        w={"full"}
        h={"100vh"}
        m={"0 auto"}
        p={8}
      >
        <Box
          w={"100%"}
          h={"100%"}
          maxW={"1200px"}
          m={"0 auto"}
          p={8}
          borderRadius={"25px"}
          bgColor={"white"}
        >
          <Calendar
            eventPropGetter={event_style_getter}
            localizer={momentLocal}
            events={eventsList}
            popup
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handle_select_event}
            messages={{
              next: "Næste",
              today: "I dag",
              previous: "Tilbage",
              month: "Måned",
              week: "Uge",
              day: "Dag",
              agenda: "Dagsorden",
              noEventsInRange: "Der er ingen aftaler lige nu.",
              showMore: (total) => `+${total} Mere aftaler`,
            }}
            formats={{
              dayRangeHeaderFormat: ({start, end}, culture, localizer) => {
                const currentMonth = localizer.format(start, "MMMM", culture);
                const monthName = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
                const weekNumber = moment(start).week();
                return `${monthName} - Uge ${weekNumber}`;
              },
            }}
            defaultView="week"
            min={new Date(2024, 9, 25, 10, 0)} // Start at 10 AM
            max={new Date(2024, 9, 25, 20, 0)} // End at 8 PM
          />
        </Box>
        <div>
          {/* Appointment details modal*/}
          <Modal
            onClose={onFirstClose}
            size="xl"
            isOpen={isFirstOpen}
            style={{height: "800px"}}
            isClosable={false}
          >
            <ModalOverlay/>
            <ModalContent
              style={{
                position: "relative",
                borderRadius: "6%",
                border: "#edeaef 4px solid",
              }}
            >
              <ModalHeader style={{fontFamily: "Poppins"}}>
                <PopUpHeader
                  currentPopupStatus={currentPopupStatus}
                  setCurrentPopupStatus={setCurrentPopupStatus}
                />
              </ModalHeader>

              <ModalBody>
                {selected_event ? (
                  (() => {
                    switch (currentPopupStatus) {
                      case "service":
                        return (
                          <ServicesInformation
                            services={services}
                            setCurrentPopupStatus={setCurrentPopupStatus}
                            setSelectedServiceIndex={setSelectedServiceIndex}
                          />
                        ); // Return JSX
                      case "subService":
                        return (
                          <SubServicesInformation
                            services={services}
                            selectedServiceIndex={selectedServiceIndex}
                            selected_event={selected_event}
                            set_selected_event={set_selected_event}
                          />
                        ); // Return JSX
                      case "tags":
                        return (
                          <TagsInformation
                            tags={tags}
                            setSelectedTagIndex={setSelectedTagIndex}
                            setCurrentPopupStatus={setCurrentPopupStatus}
                            getProductsByTags={getProductsByTags}
                          />
                        ); // Return JSX
                      case "tagProducts":
                        return (
                          <ProductsInformation
                            tags={tags}
                            products={products}
                            selectedTagIndex={selectedTagIndex}
                            currentBottles={currentBottles}
                            setCurrentBottles={setCurrentBottles}
                            selected_event={selected_event}
                            set_selected_event={set_selected_event}
                          />
                        );
                      case "calendar":
                        return (
                          <DateAndTimePicker
                            selectedServices={selected_event.selectedServices}
                            onDateTimeSelected={handleDateTimeSelected}
                            closedDays={closedDays}
                          />
                        );
                      default:
                        return (
                          <AppointmentInformation
                            selected_event={selected_event}
                            getServices={getServices}
                            getTags={getTags}
                            setCurrentPopupStatus={setCurrentPopupStatus}
                            declineAppointment={declineAppointment}
                          />
                        ); // Return JSX
                    }
                  })()
                ) : (
                  <></>
                )}
              </ModalBody>
              <ModalFooter>
                {(() => {
                  switch (currentPopupStatus) {
                    case "subService":
                      return (
                        <Button
                          background={"none"}
                          border={"3px solid #cdbeb9"}
                          fontWeight={"normal"}
                          fontSize={"large"}
                          borderRadius={"25px"}
                          onClick={() => {
                            setCurrentPopupStatus("main");
                            addNewService();
                          }}
                        >
                          Opdater
                        </Button>
                      );
                    case "tagProducts":
                      return (
                        <Button
                          background={"none"}
                          border={"3px solid #cdbeb9"}
                          fontWeight={"normal"}
                          fontSize={"large"}
                          borderRadius={"25px"}
                          onClick={() => {
                            setCurrentPopupStatus("main");
                            addNewProduct();
                          }}
                        >
                          Opdater
                        </Button>
                      );
                    case "calendar":
                      return (
                        <Button
                          background={"none"}
                          border={"3px solid #cdbeb9"}
                          fontWeight={"normal"}
                          fontSize={"large"}
                          borderRadius={"25px"}
                          onClick={() => {
                            updateAppointmentDateTime();
                          }}
                        >
                          Opdater
                        </Button>
                      );
                    default:
                      return (
                        <Button
                          isDisabled={
                            selected_event &&
                            (selected_event.appointment_status === "declined" ||
                              selected_event.appointment_status === "completed")
                          }
                          background={"none"}
                          border={"3px solid #cdbeb9"}
                          fontWeight={"normal"}
                          fontSize={"large"}
                          borderRadius={"25px"}
                          onClick={() => {
                            onFirstClose();
                            onSecondOpen();
                            setCurrentPopupStatus("main");
                          }}
                        >
                          Til kurv
                        </Button>
                      );
                  }
                })()}
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Checkout appointment modal*/}
          <Modal
            onClose={onSecondClose}
            size="5xl"
            isOpen={isSecondOpen}
            style={{height: "800px"}}
            isClosable={false}
          >
            <ModalOverlay/>
            <ModalContent
              style={{
                position: "relative",
                borderRadius: "6%",
                border: "#edeaef 4px solid",
              }}
            >
              <ModalBody>
                <Flex
                  paddingLeft={"20px"}
                  paddingRight={"20px"}
                  paddingTop={"30px"}
                >
                  <Box
                    style={{
                      position: "relative",
                      borderRadius: "6%",
                      border: "#edeadf 4px solid",
                      width: "43%",
                    }}
                  >
                    <Image
                      src={process.env.PUBLIC_URL + "/assets/Logo/A_logo.png"}
                      alt=""
                      width="50%"
                      margin="auto"
                      marginTop="30px"
                    />
                    <Divider
                      width={"80%"}
                      margin={"auto"}
                      marginTop={"40px"}
                      marginBottom={"40px"}
                      borderBottom={"3px solid #edeadf"}
                    />
                    <Flex
                      width={"80%"}
                      fontSize={"18px"}
                      margin={"auto"}
                      marginBottom={"1.2rem"}
                      borderBottom={"3px solid #edeadf"}
                    >
                      <Text fontWeight={"bold"} width={"55%"}>
                        Aftale status:
                      </Text>
                      <Text>
                        {selected_event?.appointment_status === "accepted"
                          ? "Godkendt"
                          : selected_event?.appointment_status === "declined"
                            ? "Afvist"
                            : selected_event?.appointment_status === "completed"
                              ? "Gennemført"
                              : "Afventer"}
                      </Text>
                    </Flex>
                    <Flex
                      width={"80%"}
                      fontSize={"18px"}
                      margin={"auto"}
                      marginBottom={"1.2rem"}
                      borderBottom={"3px solid #edeadf"}
                    >
                      <Text fontWeight={"bold"} width={"55%"}>
                        Kundenavn:
                      </Text>
                      <Text>{checkoutData?.clientName}</Text>
                    </Flex>
                    <Flex
                      width={"80%"}
                      fontSize={"18px"}
                      margin={"auto"}
                      marginBottom={"1.2rem"}
                      borderBottom={"3px solid #edeadf"}
                    >
                      <Text fontWeight={"bold"} width={"55%"}>
                        Medlemskab:
                      </Text>
                      <Text>BASIS</Text>
                    </Flex>

                    <Flex
                      width="85%"
                      margin="auto"
                      marginTop="40px"
                      position="relative"
                    >
                      <Input
                        placeholder="Indtast rabatkode"
                        borderWidth="3px"
                        borderStyle="solid"
                        borderColor="#edeadf"
                        borderRadius="25px"
                        px="20px"
                        height="50px"
                        _hover={{borderColor: "#cdbeb9"}}
                        _focus={{borderColor: "#cdbeb9", boxShadow: "none"}}
                        disabled={true}
                      />
                      <Button
                        background="#edeadf"
                        position="absolute"
                        top="0"
                        right="0"
                        zIndex={99}
                        border="3px solid #cdbeb9"
                        borderRadius="25px"
                        height="50px"
                        width="110px"
                      >
                        Rabat
                      </Button>
                    </Flex>
                    <Flex
                      w={"85%"}
                      m={"auto"}
                      my={"40px"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text>Returpant? Klik her</Text>
                      <Flex alignItems={"center"}>
                        <Button
                          onClick={() =>
                            setPantQuantity(
                              pantQuantity > 0 ? pantQuantity - 1 : 0
                            )
                          }
                          variant="ghost"
                          size="xs"
                        >
                          <FaMinus/>
                        </Button>
                        <Button
                          marginLeft={"5px"}
                          marginRight={"5px"}
                          background={"#edeadf"}
                          border="3px solid #cdbeb9"
                          borderRadius={"20px"}
                          height={"40px"}
                          width={"110px"}
                          fontSize={"14px"}
                        >
                          PANT
                          <span
                            style={{
                              marginLeft: "4px",
                              marginRight: "4px",
                              fontSize: "14px",
                              color: "#cdbeb9",
                            }}
                          >
                            {" "}
                            |
                          </span>
                          {` -${pantQuantity * 20} Kr`}
                        </Button>
                        <Button
                          onClick={() => setPantQuantity(pantQuantity + 1)}
                          variant="ghost"
                          size="xs"
                        >
                          <FaPlus/>
                        </Button>
                      </Flex>
                    </Flex>
                  </Box>
                  <Box w={"55%"} m={"auto 0 auto 20px"}>
                    <Text
                      fontWeight={"bold"}
                      fontSize={"2xl"}
                      borderBottom={"3px solid #edeadf"}
                      width={"100%"}
                    >
                      Din indkøbskurv:
                    </Text>
                    <Grid
                      fontWeight={"bold"}
                      color={"#cfc0bb"}
                      my={"20px"}
                      gridTemplateColumns={"3fr 2fr 2fr"}
                    >
                      <Text justifySelf={"start"}>Beskrivelse</Text>
                      <Text justifySelf={"center"}>Antal</Text>
                      <Text justifySelf={"end"}>Pris</Text>
                    </Grid>

                    <Text
                      fontWeight={"bold"}
                      fontSize={"xl"}
                      borderBottom={"3px solid #edeadf"}
                      width={"100%"}
                    >
                      Services:
                    </Text>
                    {checkoutData?.subservices?.map((service, index) => (
                      <Grid
                        borderBottom={"2px solid #edeadf"}
                        gridTemplateColumns={"3fr 2fr 2fr"}
                        marginTop={"20px"}
                        marginBottom={"20px"}
                        key={index}
                      >
                        <Text justifySelf={"start"}>{service.name}</Text>
                        <Text justifySelf={"center"}></Text>{" "}
                        {/* todo: Quantity */}
                        <Text fontWeight={"bold"} justifySelf={"end"}>
                          {service.price} kr.
                        </Text>
                      </Grid>
                    ))}

                    <Text
                      fontWeight={"bold"}
                      fontSize={"xl"}
                      borderBottom={"3px solid #edeadf"}
                      width={"100%"}
                    >
                      Produkter:
                    </Text>
                    {checkoutData?.products?.map((product, index) => (
                      <Grid
                        borderBottom={"2px solid #edeadf"}
                        gridTemplateColumns={"3fr 2fr 2fr"}
                        marginTop={"20px"}
                        marginBottom={"20px"}
                        key={index}
                      >
                        <Text justifySelf={"start"}>
                          {product.name} - {product.size} ml
                        </Text>
                        <Text justifySelf={"center"} fontWeight={"bold"}>
                          1
                        </Text>
                        <Text justifySelf={"end"} fontWeight={"bold"}>
                          {product.price} kr.
                        </Text>
                      </Grid>
                    ))}

                    <Text
                      fontWeight={"bold"}
                      fontSize={"xl"}
                      borderBottom={"3px solid #edeadf"}
                      width={"100%"}
                    >
                      Rabatter:
                    </Text>
                    <Flex
                      color={"#cfc0bb"}
                      borderBottom={"2px solid #edeadf"}
                      marginTop={"10px"}
                    >
                      <Box flex={"3"}>BASIS</Box>
                      <Box flex={"2"}></Box>
                      <Box fontWeight={"bold"} flex={"2"}></Box>
                    </Flex>
                    {pantQuantity > 0 && (
                      <Grid
                        gridTemplateColumns={"3fr 2fr 2fr"}
                        borderBottom={"2px solid #edeadf"}
                        my={"10px"}
                        color={"#cfc0bb"}
                      >
                        <Text justifySelf={"start"}>PANT</Text>
                        <Text justifySelf={"center"}>{pantQuantity}</Text>
                        <Text fontWeight={"bold"} justifySelf={"end"}>
                          -{pantQuantity * 20} Kr.
                        </Text>
                      </Grid>
                    )}

                    <Flex
                      justifyContent={"space-between"}
                      fontWeight={"bold"}
                      fontSize={"2xl"}
                      width={"100%"}
                      mt={"10px"}
                    >
                      <Text>Total:</Text>
                      <Text>
                        {(checkoutData?.checkoutTotalPrice || 0) -
                          pantQuantity * 20 +
                          " kr."}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  background={"none"}
                  border={"3px solid #cdbeb9"}
                  fontWeight={"normal"}
                  fontSize={"large"}
                  borderRadius={"25px"}
                  onClick={() => {
                    onSecondClose();
                    onPaymentOpen();
                  }}
                >
                  Gå til betaling
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Payment methods modal*/}
          <Modal
            onClose={onPaymentClose}
            size="xl"
            isOpen={isPaymentOpen}
            style={{height: "800px"}}
            isClosable={false}
          >
            <ModalOverlay/>
            <ModalContent
              style={{
                position: "relative",
                borderRadius: "6%",
                border: "#edeaef 4px solid",
              }}
            >
              <ModalHeader style={{fontFamily: "Poppins"}}>
                <Text
                  borderBottom="2px solid"
                  borderColor="#edeaef"
                  width={"fit-content"}
                >
                  Hvordan vil kunden betale?
                </Text>
              </ModalHeader>

              <ModalBody>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "16px",
                    gridAutoRows: "min-content",
                    alignContent: "start",
                    overflowY: "auto",
                    paddingBottom: "16px",
                  }}
                >
                  {/* Card Payment */}
                  <Box>
                    <Button
                      border="4px solid #cdbeb9"
                      borderRadius="30%"
                      height="150px"
                      width="160px"
                      display="flex"
                      marginBottom="10px"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="#F7FAFB"
                      _hover={{
                        backgroundColor: "#f1eeec",
                      }}
                      onClick={() => {
                        makeFlatpayPayment();
                      }}
                    >
                      <Image
                        src="https://res.cloudinary.com/dxg9wvfwv/image/upload/v1730304069/two_cupi4v.png"
                        alt="Card Payment"
                        boxSize="70%"
                        objectFit="contain"
                      />
                    </Button>
                    <Text fontSize="lg" fontWeight="500" textAlign="center">
                      Kort
                    </Text>
                  </Box>

                  {/* Cash Payment */}
                  <Box>
                    <Button
                      border="4px solid #cdbeb9"
                      borderRadius="30%"
                      height="150px"
                      width="160px"
                      display="flex"
                      marginBottom="10px"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="#F7FAFB"
                      _hover={{
                        backgroundColor: "#f1eeec",
                      }}
                      onClick={makeCashPayment}
                    >
                      <Image
                        src="https://res.cloudinary.com/dxg9wvfwv/image/upload/v1730388212/money_vq9lf7.png"
                        alt="Cash Payment"
                        boxSize="70%"
                        objectFit="contain"
                      />
                    </Button>
                    <Text fontSize="lg" fontWeight="500" textAlign="center">
                      Kontant
                    </Text>
                  </Box>

                  {/* MobilePay Payment */}
                  <Box>
                    <Button
                      border="4px solid #cdbeb9"
                      borderRadius="30%"
                      height="150px"
                      width="160px"
                      display="flex"
                      marginBottom="10px"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="#F7FAFB"
                      _hover={{
                        backgroundColor: "#f1eeec",
                      }}
                      onClick={() => {
                        toast({
                          title: "Info",
                          description: "MobilePay er endnu ikke implementeret.",
                          status: "info",
                          duration: 3000,
                          isClosable: true,
                        });
                      }}
                    >
                      <Image
                        src="https://res.cloudinary.com/dxg9wvfwv/image/upload/v1730388332/mobile-payment_fdaxht.png"
                        alt="MobilePay Payment"
                        boxSize="70%"
                        objectFit="contain"
                      />
                    </Button>
                    <Text fontSize="lg" fontWeight="500" textAlign="center">
                      Mobilepay
                    </Text>
                  </Box>
                </div>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>

          {/* Payment Status Modal */}
          <PaymentStatusModal
            isOpen={isPaymentStatusOpen}
            onClose={onPaymentStatusClose}
            status={paymentStatus}
            message={paymentMessage}
          />
        </div>
      </Box>
    </>
  );
}

export default CalendarPage;
