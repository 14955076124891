import { Button, Input, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Loginright from "./Loginright";
import "./Newpass.css";
import { Authcontext } from "./context/Authcontext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Newpass() {
  // store data from inputs
  const [inputs, setInputs] = useState({
    password: "",
    repeatpassword: "",
  });

  // call loginsaloon function from state and return response
  // get current user to check if saloon is already connected or not
  const { currentuser } = useContext(Authcontext);

  // handle animation for loading requests
  const [isLoading, setisLoading] = useState(false);

  // handle naviguation between pages
  const navigate = useNavigate();

  // declare toast for notifications
  const toast = useToast();

  // redirect if there is a current user to dashboard
  useEffect(() => {
    if (currentuser) {
      navigate("/booking-system/dashboard");
    }
  }, [currentuser, navigate]);

  // handle getting the data from the inputs
  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // handle submitting the form
  const handlesubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      if (inputs.password === "" || inputs.repeatpassword === "") {
        toast({
          title: "Skriv fejl",
          description: "Adgangskode eller gentag adgangskode er tomt",
          position: "top-right",
          isClosable: true,
          status: "warning",
        });
        setisLoading(false);
      } else {
        if (inputs.password !== inputs.repeatpassword) {
          toast({
            title: "Skriv fejl",
            description: "Feltet Gentag adgangskode er forkert",
            position: "top-right",
            isClosable: true,
            status: "warning",
          });
          setisLoading(false);
        } else {
          const res = await axios.put(
            `/saloon/reset/${localStorage.getItem("forgotpasssaloon")}`,
            { password: inputs.password }
          );

          if (res.status === 200) {
            navigate("/booking-system/login");
            setisLoading(false);
            localStorage.setItem("forgotpasssaloon", null);
          }
        }
      }
    } catch (err) {
      if (err.status === 404) {
        toast({
          title: "Login fejl",
          description: err.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      } else {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisLoading(false);
    }
  };

  return (
    <>
      <div className="Newpass">
        <div className="Newpasscontainer">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
              alt=""
              className="Logocontainer"
            ></img>
          </Link>
          <div className="Header">Nulstil Din Adgangskode</div>
          <div className="Subheader">indtast venligst din adgangskode.</div>
          <div className="Emailabel">Adgangskode</div>
          <Input
            variant="filled"
            size="lg"
            className="Emailinput"
            name="password"
            onChange={handlechange}
          />
          <div className="Passlabel">Bekræft Kodeord</div>
          <Input
            variant="filled"
            size="lg"
            className="Passinput"
            name="repeatpassword"
            onChange={handlechange}
          />
          {!isLoading ? (
            <>
              <Button className="Submitbtn" onClick={handlesubmit}>
                Indsend
              </Button>
            </>
          ) : (
            <>
              <Button className="Submitbtn" isLoading loadingText="Indlæser">
                Indsend
              </Button>
            </>
          )}
        </div>
        <Loginright className="Loginright" />
      </div>
    </>
  );
}

export default Newpass;
