import { Button, Divider, Input, useToast } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Location.css";
import axios from "axios";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  Autocomplete,
} from "@react-google-maps/api";
import Cookies from "js-cookie"
function Location() {
  // check if button is loading
  const [isloading, setisloading] = useState(false);
  // check if location is set or not
  const [locationset, setlocationset] = useState(false);
  // declare toast for notifications
  const toast = useToast();
  // refresh key to refresh page after update
  const [refreshKey, setRefreshKey] = useState(0);
  // set final options for select
  const [finaloptions, setfinaloptions] = useState([]);
  // set the lattitude and longtitude of the location
  const [lat, setlat] = useState(55.6761);
  const [lon, setlon] = useState(12.5683);
  // set the initial position for the map
  const [position, setposition] = useState([55.6761, 12.5683]);
  // get location from the marker when dragged
  const markerRef = useRef(null);
  const [Latdb, setLatdb] = useState(0);
  const [Lngdb, setLngdb] = useState(0);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setLatdb(marker.getLatLng().lat);
          setLngdb(marker.getLatLng().lng);
          setlocationset(true);
        }
      },
    }),
    []
  );
  // handle upadating the location
  const handleUpdateLocation = async (e) => {
    console.log(Latdb);
    setisloading(true);
    e.preventDefault();
    console.log(markerPosition.lat, markerPosition.lng);
    try {
      const res = await axios.put("/saloon/location", {
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      }, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization":Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Placering opdateret",
          description: "Nu vil dine kunder se din nøjagtige placering",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setisloading(false);
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      setisloading(false);
    }
  };
  // get the location on page load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/saloon/location", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization":Cookies.get("authenticationToken")
          }
        });
        if (res.status === 200) {
          setlat(res.data.lat);
          setlon(res.data.lng);
          setposition([res.data.lat, res.data.lng]);
          setMarkerPosition({
            lat: res.data.lat,
            lng: res.data.lng,
          });
        }
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const libraries = ["places"];
  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };
  const center = {
    lat: 37.7749,
    lng: -122.4194,
  };

  // marker showed in the map
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  // loading the map
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDEVQV4EJJVG38U21BYV5jp1pvbCHRyQNM",
    libraries,
  });

  // set the marker on the clicked point of the map
  const handleMapClick = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  // get coordinates when dragging the marker
  const handleMarkerDrag = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const [searchResult, setSearchResult] = useState("");
  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  // for place input get the place and its coordinates
  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const name = place.name;
      const status = place.business_status;
      const formattedAddress = place.formatted_address;
      console.log(`Name: ${name}`);
      console.log(`Business Status: ${status}`);
      console.log(`Formatted Address: ${formattedAddress}`);
    } else {
      alert("Please enter text");
    }
  }

  // to set the marker on the searched place
  function handleClick() {
    if (searchResult !== null) {
      const place = searchResult.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setMarkerPosition({ lat, lng });

      console.log(`Latitude: ${lat}`);
      console.log(`Longitude: ${lng}`);
    } else {
      alert("Please enter text");
    }
  }

  // to restrict map only on denmark
  const denmarkBounds = {
    north: 57.748888,
    south: 53.323328,
    east: 15.625723,
    west: 8.075246,
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <>
      <div className="Location">
        <div className="Locationsearchcontainer">
          <Autocomplete
            options={{
              types: ["address"],
              componentRestrictions: { country: "dk" },
            }}
            onPlaceChanged={onPlaceChanged}
            onLoad={onLoad}
            className="Searchinput"
          >
            <Input
              w={"100%"}
              type="text"
              placeholder="Søg efter Tide Information"
            />
          </Autocomplete>

          <Button className="Searchbtn" onClick={handleClick}>
            Søg
          </Button>
        </div>
        <Divider className="Devider" />
        <div className="Mapheader">Vælg en placering på kortet</div>
        <div className="Mapcontainer">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={16}
            center={markerPosition}
            onClick={handleMapClick}
            options={{
              restriction: {
                latLngBounds: denmarkBounds,
                strictBounds: false,
              },
            }}
          >
            <MarkerF
              position={markerPosition}
              draggable
              onDragEnd={handleMarkerDrag}
            />
          </GoogleMap>
          <Button className="Updatebtn" onClick={handleUpdateLocation}>
            Opdatering
          </Button>
        </div>
      </div>
    </>
  );
}

export default Location;
