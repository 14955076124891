import axios from "axios";

import { createContext, useEffect, useState } from "react";

export const Authcontext = createContext();

export const AuthcontextProvider = ({ children }) => {
  const [currentuser, setCurrentuser] = useState(
    JSON.parse(localStorage.getItem("saloonuser")) || null
  );

  const loginsaloon = async (inputs) => {
    const res = await axios.post("saloon/login", inputs);
    setCurrentuser(res.data);
    return res;
  };

  const logoutsaloon = async () => {
    const res = await axios.get("saloon/logout");
    setCurrentuser(null);
    return res;
  };

  useEffect(() => {
    localStorage.setItem("saloonuser", JSON.stringify(currentuser));
  }, [currentuser]);

  return (
    <Authcontext.Provider value={{ currentuser, loginsaloon, logoutsaloon }}>
      {children}
    </Authcontext.Provider>
  );
};
