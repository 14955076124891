import { Button, Input, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useRef, useState } from "react";
import "./Security.css";
import Cookies from "js-cookie"
function Security() {
  const oldpassRef = useRef();
  const newpassRef = useRef();
  const repeatpassRef = useRef();
  const [isMatch, setIsMatch] = useState(true);
  const [isCorrectPass, setIsCorrectPass] = useState(true);

  const [error, setErr] = useState(null);
  const toast = useToast();
  // Update Saloon Password
  const handleUpdateSaloonPass = async (e) => {
    e.preventDefault();
    setIsMatch(true);
    setIsCorrectPass(true);
    if (newpassRef.current.value === repeatpassRef.current.value) {
      try {
        const saloonData = {
          oldpassword: oldpassRef.current.value,
          newpassword: newpassRef.current.value,
        };

        const res = await axios.put(`/saloon/security`, saloonData, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        if (res.status === 200) {
          oldpassRef.current.value = "";
          repeatpassRef.current.value = "";
          newpassRef.current.value = "";
          toast({
            title: "Virksomheds adgangskode opdateret",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }
      } catch (err) {
        setErr(err.response);
        if (error.data.status === 404 || error.data.status === 405) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et par minutter",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        } else {
          if (error.data.status === 400) {
            setIsCorrectPass(false);
            toast({
              title: "Forkert kodeord",
              position: "top-right",
              isClosable: true,
              status: "error",
            });
          }
        }
      }
    } else {
      setIsMatch(false);
      toast({
        title: "Adgangskode stemmer ikke overens",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };
  return (
    <>
      <div className="Security">
        <div className="Securityheader">Administrer din adgangskode</div>
        <div className="Oldpasscontainer">
          <div>Gammelt kodeord</div>
          <Input
            type="password"
            variant="filled"
            ref={oldpassRef}
            className={isCorrectPass ? "" : "redError"}
          />
        </div>
        <div className="Newpasscontainer">
          <div>Nyt kodeord</div>
          <Input
            type="password"
            variant="filled"
            ref={newpassRef}
            className={isMatch ? "" : "redError"}
          />
        </div>
        <div className="Confirmpasscontainer">
          <div>Bekræft kodeord</div>
          <Input
            type="password"
            variant="filled"
            ref={repeatpassRef}
            className={isMatch ? "" : "redError"}
          />
        </div>
        <Button className="Updatebtn" onClick={handleUpdateSaloonPass}>
          Opdatering
        </Button>
      </div>
    </>
  );
}

export default Security;
