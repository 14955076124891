import React from "react";
import { Button, Flex, Text, keyframes } from "@chakra-ui/react";
import Lottie from 'react-lottie'; // Import Lottie
import ErrorAnimation from "../public/Animation - 1732786683659.json"; // Import your Lottie JSON file

// Animation for the container
const bounce = keyframes`
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
`;

function MobilePage() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ErrorAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet' // Changed to 'meet' to avoid overflow
    }
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
      background="linear-gradient(135deg, #F7FAFB, #E0E0E0)"
      padding="20px"
      textAlign="center"
      animation={`${bounce} 2s infinite`}
    >
      <Lottie options={defaultOptions} height={200} width={150} />
      <Text fontSize="2xl" fontWeight="bold" marginTop="20px" color="#1A1A2E">
        Denne app understøttes ikke på denne skærm.
      </Text>
      <Text fontSize="lg" marginTop="10px" color="#494949">
        Skift venligst til en tablet eller desktop skærm.
      </Text>
      <Button
        marginTop="20px"
        padding="10px 20px"
        background="#1A1A2E"
        color="#FFFFFF"
        borderRadius="5px"
        onClick={() => window.location.reload()}
      >
        Opdater
      </Button>
    </Flex>
  );
}

export default MobilePage;
