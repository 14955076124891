import React, { useContext } from "react";
import "./BusinesssidebarMobile.css";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  useToast,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  MdSpaceDashboard,
  MdCalendarToday,
  MdEditCalendar,
  MdMedicalServices,
  MdPersonAddAlt1,
  MdOutlineStarHalf,
  MdCreditCard,
  MdUpgrade,
  MdSettings,
  MdOutlineLogout,
  MdQueryStats,
} from "react-icons/md";
import { Authcontext } from "./context/Authcontext";
import Cookies from "js-cookie"
import axios from "axios";
import { BsBoxSeam } from "react-icons/bs";

function BusinesssidebarMobile() {
  // handle opening and closing the sidebar accordion
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  // handle naviguation between pages
  const navigate = useNavigate();


  // declare toast for notifications
  const toast = useToast();

  // handle removing cookies
  function removeCookiePromise(key) {
    return new Promise((resolve, reject) => {
      try {
        Cookies.remove(key);
        resolve("Cookie removed successfully");
      } catch (error) {
        reject(error);
      }
    });
  }
  // handle logging out of dashboard
  // handle logging out of dashboard
  const logout = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.get("/saloon/logout", {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 204) {
        removeCookiePromise("authenticationToken")
          .then(() => {
            window.location.href = "/log-ind";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      else if (res.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      else if (res.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Incorrect Or Expired token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        removeCookiePromise("authenticationToken")
          .then(() => {
            window.location.href = "/log-ind";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "prøv venligst igen om nogle minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <div className="BusinesssidebarMobile">
        <div className="Drawercontainer">
          <IconButton
            ref={btnRef}
            icon={<HamburgerIcon />}
            onClick={onOpen}
            className="Drawerbtn"
          >
            Åben
          </IconButton>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Logo/green.png"}
                  alt=""
                  className="greencontainerTop"
                ></img>
                <div className="TopSidebar" style={{borderBottom:"2px solid black"}}>
                  <Link to="#">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/Logo/A_logo.png"}
                      alt=""
                      className="Logocontainer"
                      style={{ width: "100px", margin: "auto",marginTop:"10px" }}
                    ></img>
                    <Text width={"fit-content"} margin={"auto"} marginBottom={0} marginTop={1} fontWeight={"bold"} fontSize={"12px"}>By BUCH</Text>
                  </Link>
                </div>
              </div>

              <DrawerBody>
                <div className="Nav">
                  <Link
                    to="/bookingsystem/kontrolpanel"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdSpaceDashboard}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Kontrolpanel
                  </Link>
                  <Link
                    to="/bookingsystem/kalender"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdCalendarToday}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Kalender
                  </Link>
                  {/* <Link
                    to="/bookingsystem/aftaler"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon as={MdEditCalendar} className="Navicon" w={5} h={5} />{" "}
                    Aftaler
                  </Link> */}
                  <Link
                    to="/bookingsystem/tjenester"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdMedicalServices}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Tjenester
                  </Link>
                  <Link
                    to="/bookingsystem/personale"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdPersonAddAlt1}
                      className="Navicon"
                      w={5}
                      h={5}
                    />
                    Personale
                  </Link>
                  <Link
                    to="/bookingsystem/produkter"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={BsBoxSeam}
                      className="Navicon"
                      w={5}
                      h={5}
                    />
                    Produkter
                  </Link>
                  <Link
                    to="/bookingsystem/anmeldelser"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdOutlineStarHalf}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Anmeldelser
                  </Link>
                  <Link
                    to="/bookingsystem/statistik"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon as={MdQueryStats} className="Navicon" w={5} h={5} />{" "}
                    Statistik
                  </Link>
                  {/* <Link
                    to="/bookingsystem/betaling"
                    className="Navitem isDisabled"
                    onClick={onClose}
                  >
                    <Icon as={MdCreditCard} className="Navicon" w={5} h={5} />{" "}
                    Betaling
                    <span className="soon_tag">snart</span>
                  </Link> */}
                  {/* <Link
                    to="/bookingsystem/opgradere"
                    className="Navitem isDisabled"
                    onClick={onClose}
                  >
                    <Icon as={MdUpgrade} className="Navicon" w={5} h={5} />{" "}
                    Opgrader
                    <span className="soon_tag">snart</span>
                  </Link> */}
                  <Link
                    to="/bookingsystem/indstillinger/sec/generelinformation"
                    className="SettingsNavitem"
                  >
                    <Accordion allowMultiple className="Settingsaccord">
                      <AccordionItem className="Settingsaccord">
                        <AccordionButton>
                          <Box flex="1" textAlign="left" className="Box">
                            <Icon
                              as={MdSettings}
                              className="SettingsNavicon"
                              w={5}
                              h={5}
                            />
                            <div className="Settingstxt">Indstillinger</div>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4} className="Settingslist">
                          <Link
                            className="Listitem"
                            to="/bookingsystem/indstillinger/sec/generelinformation"
                            onClick={onClose}
                          >
                            Generel information
                          </Link>
                          <Link
                            className="Listitem"
                            to="/bookingsystem/indstillinger/sec/kontaktinfo"
                            onClick={onClose}
                          >
                            Kontakt information
                          </Link>
                          <Link
                            className="Listitem"
                            to="/bookingsystem/indstillinger/sec/album"
                            onClick={onClose}
                          >
                            Billeder
                          </Link>
                          <Link
                            className="Listitem"
                            to="/bookingsystem/indstillinger/sec/arbejdsplan"
                            onClick={onClose}
                          >
                            Arbejdsskema
                          </Link>
                          <Link
                            className="Listitem"
                            to="/bookingsystem/indstillinger/sec/placering"
                            onClick={onClose}
                          >
                            Beliggenhed
                          </Link>
                          <Link
                            className="Listitem"
                            to="/bookingsystem/indstillinger/sec/sikkerhed"
                            onClick={onClose}
                          >
                            Sikkerhed
                          </Link>
                          <Link
                            className="Listitem"
                            to="/bookingsystem/indstillinger/sec/support"
                            onClick={onClose}
                          >
                            Support
                          </Link>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Link>
                  <span className="Navitem" id="Logout" onClick={logout}>
                    <Icon as={MdOutlineLogout} className="Navicon" w={5} h={5} />{" "}
                    Log ud
                  </span>

                </div>
              </DrawerBody>




            </DrawerContent>

          </Drawer>
        </div>
  

      </div>

    </>
  );
}

export default BusinesssidebarMobile;
